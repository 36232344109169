import { buildMapData, getUniqueMonths } from '@resistapp/client/data-utils/plot-data/build-map-data';
import { buildOverviewLineData, OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import { EnvGroup, getProcessMode } from '@resistapp/common/comparable-env-groups';
import { FullSample, NormalisationMode, ProcessMode, type MetricMode } from '@resistapp/common/types';
import { chain, Dictionary, isNil, keys, mapValues } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAssayContext } from '../../assay-context';
import { useSampleDataContext } from '../../sample-data-context';
import { determineAvailableNormalisationModes, getGlobalOverviewDatum } from '../overview-context-utils';

interface UseOverviewDataProps {
  supportedSamples: FullSample[] | undefined;
  queryParamsInitialised: boolean;
  activeEnvGroup: EnvGroup | undefined;
  metricMode: MetricMode;
}

interface UseOverviewDataResult {
  trendDataByLevel: Dictionary<OverviewDatum[][]> | undefined;
  mapDataByLevel: Dictionary<OverviewDatum[]> | undefined;
  availableMonths: string[];
  notAvailableReason: string | null;
  availableNormalisationModes: NormalisationMode[];
  processMode: ProcessMode;
}

/*
 * Encapsulates main data preparation logic for overview use context.
 * NOTE: Keep this hook totally reliant on external state changing in overview-context and shouldn't activate when
 * overview-contexts internal states are updated.
 *
 * DO NOT USE ELSEWHERE THAN ONCE IN use-overview-context.tsx
 */
export function useOverviewData({
  supportedSamples,
  queryParamsInitialised,
  activeEnvGroup,
  metricMode,
}: UseOverviewDataProps): UseOverviewDataResult {
  const { queryFilters, availableNormalisationModes, setAvailableNormalisationModesStable } = useSampleDataContext();
  const { assaysLoaded, getGroup } = useAssayContext();

  const mapDataUpdateInvocationCnt = useRef(0);
  const [trendDataByLevel, setTrendDataByLevel] = useState<Dictionary<OverviewDatum[][]> | undefined>(undefined);
  const [mapDataByLevel, setMapDataByLevel] = useState<Dictionary<OverviewDatum[]> | undefined>(undefined);
  const [availableMonths, setAvailableMonths] = useState<string[]>([]);
  const [notAvailableReason, setNotAvailableReason] = useState<string | null>(null);

  // 3.1 RE-DETERMINE AVAILABLE NORMALISATION MODES BASED ON METADATA AVAILABILITY IN SUPPORTEDSAMPLES
  useEffect(() => {
    const supportedNormalisationModes = determineAvailableNormalisationModes(
      supportedSamples,
      activeEnvGroup?.envs.map(e => e.id),
    );
    setAvailableNormalisationModesStable(supportedNormalisationModes);
  }, [supportedSamples, activeEnvGroup?.envs]);
  const processMode = getProcessMode(activeEnvGroup?.type, metricMode, supportedSamples || []);

  // 3.2 PREPARE OVERVIEW DATA
  useEffect(() => {
    if (!supportedSamples?.length || !queryParamsInitialised || !activeEnvGroup || !assaysLoaded) {
      return;
    }

    // Avoid race conditions that can easily happen eg when an original map data update with all project data is missing co-ordinates,
    // but a subsequent, map update with filtered data isn't
    const thisInvocation = ++mapDataUpdateInvocationCnt.current;
    (() => {
      try {
        const levelsInAllSamples = keys(supportedSamples[0].adminLevels);
        const poolings: Pooling[] = [...levelsInAllSamples, null].map(level =>
          isNil(level)
            ? {
                type: PoolingType.SITE,
                mode: PoolingMode.THROW_MISSING,
              }
            : {
                type: PoolingType.SITE_AND_ADMIN_LEVEL,
                mode: PoolingMode.THROW_MISSING,
                level: +level,
              },
        );

        const selectedEnvSet = new Set(activeEnvGroup.envs.map(env => env.id));
        const newTrendDataByLevel = chain(poolings)
          .keyBy(pooling => ('level' in pooling ? `${pooling.level}` : 'null'))
          // Replace groupYearly with false to always use monthly data
          .mapValues(pooling => buildOverviewLineData(supportedSamples, selectedEnvSet, processMode, pooling, getGroup))
          .value();

        const newMapDataByLevel = mapValues(newTrendDataByLevel, levelTrendData =>
          buildMapData(levelTrendData, queryFilters.filters.interval),
        );
        newMapDataByLevel['1'] = [getGlobalOverviewDatum()];
        const months = getUniqueMonths(newTrendDataByLevel['null']);

        if (mapDataUpdateInvocationCnt.current === thisInvocation) {
          setTrendDataByLevel(newTrendDataByLevel);
          setMapDataByLevel(newMapDataByLevel);
          setAvailableMonths(months);
          setNotAvailableReason(null);
        }
      } catch (err) {
        if (err instanceof Error) {
          setNotAvailableReason(err.message);
          console.error('OverviewContext error', err);
        } else {
          console.error('OverviewContext error', err);
        }
      }
    })();
  }, [
    getGroup,
    assaysLoaded,
    supportedSamples,
    queryParamsInitialised, // ^- Wait until these are ready
    queryFilters.filters.interval, // v- rerun after loading and when these change
    activeEnvGroup,
    processMode,
  ]);

  return {
    trendDataByLevel,
    mapDataByLevel,
    availableMonths,
    notAvailableReason,
    availableNormalisationModes,
    processMode,
  };
}
