import { ChangeZoomedAdminArea } from '@resistapp/client/contexts/map-context';
import { zoomAndCenterMap } from '@resistapp/client/contexts/use-overview-context/overview-context-utils';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { AdminArea, getAllOriginalEnvironmentNames } from '@resistapp/common/types';
import { isNil } from 'lodash';
import type { MapboxMap } from 'react-map-gl';

// TODO: re-create wastpan projects now that we have dynamic pooling (see also src/common/utils.ts)
// export const wastpanDemoSubProjectByRegion: PartialDict<number> = {
//   Uusimaa: 1742,
// };

// export const globalDemoSubProjectByCountry: PartialDict<number> = {
//   Finland: 1673,
//   Indonesia: 1672,
//   Nepal: 1681,
//   Thailand: 1670,
// };

// export function getCountryIfDemoSubProject(projectId: number) {
//   return Object.entries(globalDemoSubProjectByCountry).find(([_, id]) => id === projectId)?.[0];
// }

export function onMarkerClick(
  closeClick: boolean,
  marker: Pick<OverviewDatum, 'environmentAfter' | 'environment' | 'inferredLat' | 'inferredLon'>,
  _navigate: (path: string, keepQuery: boolean) => void,
  toggleEnvironment: QueryFilters['toggleEnvironmentStable'],
  adminLevelsActive: boolean,
  changeZoomedAdminAreaStable: ChangeZoomedAdminArea,
  previousAdminArea: AdminArea | undefined,
  mapInstance: MapboxMap | null,
) {
  if (closeClick) {
    // If we select a project, the projectId exists, but if we unselect it is undefined
    toggleEnvironment(undefined, true);
    changeZoomedAdminAreaStable(previousAdminArea || undefined, { previous: true });
  } else if (!adminLevelsActive) {
    toggleEnvironment(getAllOriginalEnvironmentNames(marker.environment, marker.environmentAfter), true);
    if (mapInstance && !isNil(marker.inferredLon) && !isNil(marker.inferredLat)) {
      zoomAndCenterMap(mapInstance, { zoom: 13, center: [marker.inferredLon, marker.inferredLat] });
    }
    changeZoomedAdminAreaStable(null);
  }
}
