import { EnvironmentType } from '@resistapp/common/environment-types';
import {
  Dictionary,
  chain,
  chunk,
  flatten,
  groupBy,
  intersection,
  isNil,
  keys,
  mapValues,
  pickBy,
  uniq,
  uniqBy,
} from 'lodash';
import { GetGroup } from './assays';
import type { EnvGroup } from './comparable-env-groups';
import { getSampleUID } from './sample-uid-utils';
import { FullSample, FullSamplesByUID, RawSample, type PartialDict, type Sample } from './types';

export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiamFubmVyZXNpc3RvbWFwIiwiYSI6ImNsc2xqdjNkYTBlMzEybHJua21tN3l0MmEifQ.9CxerMEg8yZP613YrwXxJg';
export const REL_ABUNDANCE_DETECTION_LIMIT = 10 ** -5;

export const TRACES_VAL = -1;

// Note: See also on-marker-click.ts if re-enabling wastpan
// export const WastPanProjectIds = [1742, 1741];
// export const WastPanProjectDescription =

//   'Antibiotic Resistance Gene Index (ARGI) in urban wastewater treatment plants in Finland. Quantification of 25 Beta Lactam resistance genes, a gene marker for fecal human pollutant and three pathogens: <i>Actinobacter baumannii,  Pseudomonas aureginosa,</i> and <i>Staphylococci</i> in raw wastewater samples collected between 2020 and 2022.';
export const oldDemoProjectIds = {
  rnd2024: 1962,
  rnd2020: 254,
  wastpan: 1741,
  brokenBlomminmaki: 1948,
  global: 1682,
  nepal: 1681,
  finland: 1673,
  indonesia: 1672,
  thailand: 1670,
};
export const demoProjectId = 2176;
export const publicProjects = [
  demoProjectId,
  297, // GEUS
  332, // R&D HUS manuscript
  337, // University of Aberystwyth
  1965, // Helsinki Bathing waters temporarily opened for 24.9.2024 presentation
];

export function getEnvironmentTypeOrWasteWater(typeKeyMaybe: string | undefined | null) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (typeKeyMaybe && EnvironmentType[typeKeyMaybe as keyof typeof EnvironmentType]) || EnvironmentType.WASTEWATER;
}

export async function sleep(ms = 0) {
  return await new Promise(r => setTimeout(r, ms));
}

export function parseSheetIdFromUrl(sheetLink: string | undefined) {
  const urlStart = 'https://docs.google.com/spreadsheets/d/';
  const SheetIdEnd = '/edit';
  const trimmedLink = sheetLink?.trim();
  if (trimmedLink && trimmedLink.indexOf(urlStart) === 0 && trimmedLink.indexOf(SheetIdEnd) > 0) {
    return trimmedLink.substring(urlStart.length, trimmedLink.indexOf(SheetIdEnd));
  } else {
    return undefined;
  }
}

export function groupBioSamples(samples: FullSample[]): FullSamplesByUID {
  return groupBy(samples, sample => getSampleUID(sample));
}

export function getBioNumber(sample: RawSample) {
  return `${sample.number}${sample.bioRep}`;
}

export function getAnalyzedGenes(samplesByUID: FullSamplesByUID): string[] {
  // All sampling samples should be analayzed wrt the same set of genes
  const samples = flattenSamplesByUID(samplesByUID);
  const relAbundances = flattenRelevantAbundances(samples);
  return uniq(relAbundances.map(datum => datum.gene));
}

export function flattenSamplesByUID(samplesByUID: FullSamplesByUID) {
  return flatten(Object.values(samplesByUID));
}

export function sortUniqEnvironments(samplesByUID: FullSamplesByUID) {
  // Numberical object keys are in numerical order
  return uniqBy(
    flattenSamplesByUID(samplesByUID).map(sample => sample.environment),
    env => env.id,
  );
}

export function countDetectedGenesPerSampleUID(samplesByUID: FullSamplesByUID) {
  return mapValues(
    samplesByUID,
    bioSamples =>
      uniq(
        flattenRelevantAbundances(bioSamples)
          .filter(datum => !!datum.relative)
          .map(datum => datum.gene),
      ).length,
  );
}

export function countDetectedGenesPerTarget(samples: FullSample[], getGroup: GetGroup) {
  const abundancesByTarget = groupBy(flattenRelevantAbundances(samples), r => getGroup(r.assay));
  return Object.keys(abundancesByTarget).reduce<Dictionary<number>>((acc, target) => {
    acc[target] = uniq(abundancesByTarget[target].filter(r => !!r.relative).map(r => r.gene)).length;
    return acc;
  }, {});
}

export function flattenAbundances(samples: FullSample[]) {
  return samples.flatMap(sample => sample.abundances);
}

export function flattenRelevantAbundances(samples: FullSample[], onlyAy1 = false) {
  return flattenAbundances(samples).filter(a => (onlyAy1 ? !not16S(a) : not16S(a)));
}

type WithAssayAndRelative = { assay: string; relative: number | null };
export function not16S(abundance: WithAssayAndRelative) {
  // TODO define based on assay info
  return abundance.assay !== 'AY1' && abundance.assay !== 'AY600' && abundance.assay !== 'AY624';
}

export function filterDetected<T extends WithAssayAndRelative>(abundances: T[]): T[] {
  return abundances.filter(v => !isNil(v.relative));
}

export const LOD = 0.00001;
export function replaceZerosWithLod(values: number[]): number[] {
  return values.map(v => (v === 0 ? LOD : v));
}

export function isStaging() {
  return getEnvironment() === 'staging';
}

export function isProduction() {
  return getEnvironment() === 'production';
}

export function getEnvironment() {
  return process.env.NODE_ENV || 'production';
}

export function isProductionLikeEnvironment() {
  return isStaging() || isProduction();
}

export function getServerUrl() {
  const env = getEnvironment();
  return env === 'production'
    ? 'https://platform.resistomap.com'
    : env === 'staging'
      ? 'https://staging.resistomap.com'
      : 'http://localhost:3001'; // replace to debug UI against prod data: https://platform.resistomap.com`
}

export function getUiUrl() {
  const env = getEnvironment();
  return env === 'production'
    ? 'https://platform.resistomap.com'
    : env === 'staging'
      ? 'https://staging.resistomap.com'
      : `http://localhost:5173`;
}

export async function chunkedAwait<A, R>(
  data: readonly A[],
  asyncFunc: (a: A, i: number) => Promise<R>,
  chunkSize = 10,
): Promise<R[]> {
  const chunks = chunk(data, chunkSize);
  const results: R[][] = [];
  for (const oneChunk of chunks) {
    const chunkResults = await Promise.all(oneChunk.map(asyncFunc));
    results.push(chunkResults);
  }
  return flatten(results);
}

export function safeAssert(condition: boolean, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message || 'Assertion failed');
  }
}

export function isAllNumeric(values: Array<number | string | null>): boolean {
  return values.every(value => typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value))));
}

// 100 represents no adminAreas (show markers on map)
export type PossibleZoomableLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
type DataType = { country?: string } & Record<string, unknown>;

/*
 * Determine admin levels whose areas we can ZOOM TO. This includes
 * - First, the default zoom level: the highest level that has only one area with samples (this level is never shown as areas)
 * - Last, zoomable level that has more than one site in some area (this level is shown as colored areas, and sites are shown when zooming into one)
 * - And usefull levels in between the first and the last level
 *   - Useless levels that have as many datums as the next area are skipped so that user always gets more data on each click
 *
 * Mind the ZOOMED area & level vs SHOWN level concepts:
 * When ZOOMING into a level 2 area, data is SHOWN on level 3 (or whatever is the next available level in all samples)
 */
export function determineZoomableLevels<T extends DataType>(
  mapDataByLevel: (Partial<Record<PossibleZoomableLevel, T[]>> & { null?: readonly T[] }) | undefined,
): PartialDict<PossibleZoomableLevel[]> {
  if (!mapDataByLevel) {
    return {};
  }
  if (!mapDataByLevel['null']) {
    throw new Error('Expected null level to exist');
  }

  if (mapDataByLevel['1'] && mapDataByLevel['1'].length > 1) {
    throw new Error('Expected maximum only one site on level 1');
  }

  const uniqueCountries = chain(mapDataByLevel['null'])
    .map(area => area.country)
    .uniq()
    .filter(country => !isNil(country))
    .value();

  const countryLevelData = uniqueCountries.reduce<PartialDict<PossibleZoomableLevel[]>>((acc, country) => {
    const countryData = chain(mapDataByLevel)
      .mapValues(levelAreas => levelAreas?.filter(area => area.country === country))
      .value();

    acc[country] = filterLevelsForCountry(countryData, country);
    return acc;
  }, {});

  return countryLevelData;
}

function filterLevelsForCountry<T extends DataType>(
  countryData: Partial<Record<PossibleZoomableLevel | 'null', T[]>>,
  selectedCountry: string | undefined,
): PossibleZoomableLevel[] {
  const numSites = countryData['null']?.length || 0;

  return (
    chain(countryData)
      .toPairs()
      // Consider non-aggregated site level as the deepest level
      .sortBy(([level]) => (isNil(level) ? 16 : +level))
      .filter(([_, levelAreas]) => levelAreas.length > 0)
      .filter(
        // Get rid of weird corner cases where a level has less areas than its upper level
        ([_, levelAreas], pairIndex, allPairs) => !pairIndex || levelAreas.length >= allPairs[pairIndex - 1][1].length,
      )
      .filter(([adminLevel, levelAreas], pairIndex, allPairs) => {
        // Skip useless levels where the next level doesn't have any more areas (note: expects that null level still exists)
        // Exception with countries, which we handle with detecting is adminLevel 2
        return (
          (!selectedCountry && adminLevel === '2' && levelAreas.length > 1) ||
          (pairIndex < allPairs.length - 1 && allPairs[pairIndex + 1][1].length > levelAreas.length)
        );
      })
      // Ensure that some area on each level has more than one data point
      // Also drops special case null site level as the last operation
      .filter(([_, levelAreas]) => {
        return levelAreas.length < numSites;
      })
      .map(([level]) => {
        return +level as PossibleZoomableLevel;
      })
      .value()
  );
}

/*
 * Get relevant samples for overview and drop admin levels that are not present in all samples
 *
 * Overview is designed for (and may make biological sense only for) selected environment types
 */
export type OverviewSample = Required<
  Pick<Sample, 'adminLevels' | 'environmentId' | 'country' | 'inferredLat' | 'inferredLon' | 'lat' | 'lon' | 'time'>
>;
export function getSupportedSamplesWithConsistentAdminLevels<T extends OverviewSample>(
  samples: T[],
  envGroups?: EnvGroup[],
): T[] {
  const supportedEnvs = new Set(envGroups?.flatMap(envGroup => envGroup.envs).map(env => env.id) || []);
  const flatSupportedSamples = samples.filter(
    sample =>
      !isNil(sample.inferredLat) &&
      !isNil(sample.inferredLon) &&
      !isNil(sample.lat) &&
      !isNil(sample.lon) &&
      !isNil(sample.time) &&
      (envGroups ? supportedEnvs.has(sample.environmentId) : true),
  );
  const levelsInAllSamples = new Set(
    chain(flatSupportedSamples)
      .map(s => s.adminLevels)
      .map(levels => keys(levels).map(level => +level))
      .reduce((acc, levels) => intersection(acc, levels))
      .value(),
  );
  const supportedSamplesWithConsistentLevels = flatSupportedSamples.map(sample => ({
    ...sample,
    adminLevels: pickBy(sample.adminLevels, datum => levelsInAllSamples.has(datum.level)),
  }));
  return supportedSamplesWithConsistentLevels;
}

export function getCountriesFromSamples(samples: Array<Pick<Sample, 'country'>>) {
  return chain(samples)
    .map(s => s.country)
    .uniq()
    .value();
}
