import { FormControl, FormHelperText, FormLabel, Input, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ResistomapButton } from '@resistapp/client/components/buttons/resistomap-button';
import { ResistomapSelect } from '@resistapp/client/components/forms/resistomap-select';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { FormView } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { MaybeApiError } from '@resistapp/client/components/states/error';
import { Loading } from '@resistapp/client/components/states/loading';
import { usePatchProject, usePostProject, useProjects } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { parseSheetIdFromUrl } from '@resistapp/common/utils';
import { useEffect, useState } from 'react';

type ProjectOption = { label: string; value: number };

export function AnalyseView() {
  const navigateStable = useNavigateWithQuery();
  const { loading: postLoading, error: postError, send: sendPost, data: postProject } = usePostProject();
  const { data: projects } = useProjects();
  const [name, setName] = useState('');
  const [sheetLink, setSheetLink] = useState('');
  const [selectedProject, setSelectedProject] = useState<ProjectOption | null>(null);

  const projectOptions: ProjectOption[] = projects ? projects.map(p => ({ label: p.name, value: p.id })).reverse() : [];

  // Always call the hook with a valid ID (first project or 0), but only use its results when a project is selected
  const {
    loading: patchLoading,
    error: patchError,
    send: patchSend,
    data: patchData,
  } = usePatchProject(selectedProject?.value || projectOptions[0]?.value || 0);
  const sendPatch = selectedProject ? patchSend : () => {};

  const loading = postLoading || (selectedProject ? patchLoading : false);
  const error = postError || (selectedProject ? patchError : undefined);

  useEffect(() => {
    if (postProject) {
      navigateStable(`/research/${postProject.id}`, false);
    }
  }, [postProject]);

  useEffect(() => {
    if (patchData) {
      navigateStable(`/research/${patchData.id}`, false);
    }
  }, [patchData]);

  return (
    <FormView>
      <RSecondTitle>New Sampling Project</RSecondTitle>
      <Stack spacing={5} width="full">
        <FormControl isRequired={!selectedProject} isDisabled={loading || !!selectedProject}>
          <FormLabel htmlFor="name">Name for new project</FormLabel>
          <Input
            id="name"
            name="name"
            value={name}
            onChange={event => {
              setName(event.target.value);
              setSelectedProject(null);
            }}
            autoFocus
            width="full"
          />
        </FormControl>

        <FormControl isDisabled={loading || !!name}>
          <FormLabel>Or select existing project to overwrite</FormLabel>
          <ResistomapSelect<ProjectOption, false>
            options={projectOptions}
            value={selectedProject}
            onChange={option => {
              setSelectedProject(option);
              setName('');
            }}
            isClearable
            placeholder="Select a project"
          />
        </FormControl>

        <FormControl isDisabled={loading}>
          <FormLabel htmlFor="sheetLink">Lab sheet link</FormLabel>
          <Input
            id="sheetLink"
            name="sheetLink"
            value={sheetLink}
            onChange={event => {
              setSheetLink(event.target.value);
            }}
            width="full"
          />
          {sheetLink && !parseSheetIdFromUrl(sheetLink) && (
            <FormHelperText>Please copy from browser URL field</FormHelperText>
          )}
        </FormControl>

        <br />
        <ResistomapButton
          onClick={() => {
            if (selectedProject) {
              const formData = new FormData();
              formData.append('sheetLink', sheetLink);
              sendPatch(formData);
            } else {
              sendPost({ name, sheetLink });
            }
          }}
          isDisabled={loading || (!name && !selectedProject) || !isValidSheetLink(sheetLink)}
        >
          {loading ? 'Analyzing...' : selectedProject ? 'Overwrite' : 'Analyze'}
        </ResistomapButton>
        <br />
        <MaybeApiError error={error} />
        {loading && <Loading />}
      </Stack>
    </FormView>
  );
}

export const Error = styled.div`
  margin-top: ${oldTheme.spacing.s};
  color: ${oldTheme.cautionRed};
`;

function isValidSheetLink(url: string) {
  return url.trim().startsWith('https://docs.google.com/spreadsheets');
}
