import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FullAbundance } from '@resistapp/common/types';
import { chain } from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { theme } from '../shared/theme';

interface Props {
  data: FullAbundance[];
  title?: string;
  hideLegend?: boolean;
}

const colors = {
  ['Universal Bacteroidales']: theme.colors.neutral500, // Assay v3.0.1
  ['Swine Bacteroidales']: theme.colors.red600,
  ['Bovine Bacteroidales']: theme.colors.chartOrangeMid,
  ['Human Bacteroides']: theme.colors.chartPurpleDark,
  universal: theme.colors.neutral500, // Assay v2.x
  swine: theme.colors.red600,
  bovine: theme.colors.chartOrangeMid,
  human: theme.colors.chartPurpleDark,
};

export function MSTHorizontalBarGraph({ data, title, hideLegend = false }: Props) {
  const [, setRefWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // Combine data from multiple samples over the sampling period
  const aggregatedData = chain(data)
    .groupBy(d => d.assay)
    .map((group, assay) => {
      const totalAbsolute = chain(group)
        .map(d => d.absolute || 0)
        .sum()
        .value();

      return {
        gene: group[0].gene,
        assay,
        absolute: totalAbsolute,
      };
    })
    .value() as FullAbundance[];

  const totalNumber = aggregatedData.reduce((sum, d) => sum + getAbundaceOrZero(d), 0);
  const sortedData = aggregatedData.sort((a, b) => getAbundaceOrZero(b) - getAbundaceOrZero(a));
  const refWidth = ref.current?.clientWidth;
  const percentages = refWidth ? Array(6).fill(1) : [];

  useEffect(() => {
    setRefWidth(ref.current?.clientWidth ?? 0);
  }, [ref.current]);

  // TODO deprecate old assay name support and make this use AY instead of gene
  const sortedColors = sortedData.map(d => {
    const geneParts = d.gene.split('-');
    return geneParts.length > 1 ? geneParts[1] : d.gene;
  });

  return (
    <Box px={5} pt={2} width="100%">
      {!hideLegend && (
        <LegendContainer>
          {sortedColors.map((key, index) => (
            <Fragment key={index}>
              <LegendText
                backgroundColor={colors[key as keyof typeof colors]}
                opacity={sortedData[index]?.absolute ? 1 : 0.4}
              />
              <Flex key={key} height="20px" pl={2} pr={6} alignItems="center">
                <span style={{ opacity: sortedData[index]?.absolute ? 1 : 0.5 }}>
                  {key.includes(' ') ? key.split(' ')[0]?.toLowerCase() : key}
                </span>
              </Flex>
            </Fragment>
          ))}
        </LegendContainer>
      )}
      <Flex flexDirection="row" width="100%">
        <Flex alignItems="center" height="60px" pr={{ base: 2, md: 6 }}>
          {title && <BarTitle>{title}</BarTitle>}
        </Flex>
        <Flex flexDirection="column" width="100%">
          <Flex ref={ref} gap="1px">
            {sortedData.map((d, i) => (
              <GeneBox key={`bar-${i}`} flexGrow={getWidth(totalNumber, d)} backgroundColor={getColor(d)} />
            ))}
          </Flex>
          {refWidth && (
            <PercentageContainer
              w={`calc(100% + ${refWidth / (percentages.length - 1)}px)`}
              ml={`-${calculateLeftMargin(percentages.length, refWidth)}px`}
              mt={300}
            >
              {percentages.map((_p, i) => (
                <PercentageBox key={`percentage-${i}`} width={`${(1 / percentages.length) * 100}%`}>
                  <PercentageText>{i * 20}%</PercentageText>
                  <VerticalLine />
                </PercentageBox>
              ))}
            </PercentageContainer>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

function getAbundaceOrZero(d: FullAbundance) {
  return d.absolute ?? 0;
}

function getWidth(totalNumber: number, d: FullAbundance) {
  return getAbundaceOrZero(d) / totalNumber;
}

function getColor(d: FullAbundance) {
  const geneParts = d.gene.split('-');
  const colorKey = geneParts.length > 1 ? geneParts[1] : d.gene;
  return colors[colorKey as keyof typeof colors];
}

// The stupid after save features were stripping the math equation to work incorrectly, so separated here
function calculateLeftMargin(percentages: number, refWidth: number) {
  return refWidth / ((percentages - 1) * 2);
}

const PercentageText = styled.div`
  color: ${theme.colors.neutral500};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.heavy};
  height: 20px;
  padding-top: 6px;
`;

const VerticalLine = styled.div`
  height: 84px;
  width: 1px;
  background-color: ${theme.colors.neutral300};
`;

const PercentageBox = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -20px;
`;

const PercentageContainer = styled(Flex)`
  height: 108px;
  padding-top: 6px;
  margin-top: -56px;
`;

const LegendContainer = styled(Flex)`
  padding-bottom: ${theme.spacing[8]};
  align-items: center;
  color: ${theme.colors.neutral700};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
`;

const GeneBox = styled(Box)`
  height: 60px;
  border-radius: ${theme.borders.radius.small};
`;

const LegendText = styled(Box)`
  height: 9px;
  min-width: 10px;
`;

const BarTitle = styled(Flex)`
  justify-content: flex-end; // Changed from center to flex-end for right alignment
  align-items: center;
  color: ${theme.colors.neutral700};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
  width: 35px;
`;
