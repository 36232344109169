import { theme } from '@resistapp/client/components/shared/theme';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useTrendChartContext } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import type { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getMetricAndLevel, getMetricColor } from '@resistapp/client/utils/metric-utils';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { LinearGradient } from '@visx/gradient';
import { scaleLinear } from '@visx/scale';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { positioning } from '../chart-styles';
import { getGradientScale } from '../scales';
import { BaseChart } from './base-chart';

export function ReductionChart() {
  const { queryFilters } = useSampleDataContext();
  const { getGroup, allAssays } = useAssayContext();
  const {
    graphHeight,
    trenchartTooltip: { trendChartSize, TooltipComponentForReduction },
  } = useTrendChartContext();
  const { activeChartUnit, activeOverviewConfiguration } = useOverviewContext();

  const indexScale = useMemo(
    () =>
      getGradientScale(graphHeight, [
        activeOverviewConfiguration.detailBarGraphMin,
        activeOverviewConfiguration.detailBarGraphMax(),
      ]),
    [graphHeight, activeOverviewConfiguration],
  );

  const labelScale = useMemo(
    () =>
      activeOverviewConfiguration.id === 'reduction-relative' || activeOverviewConfiguration.id === 'reduction-volume'
        ? scaleLinear({
            domain: [activeOverviewConfiguration.detailBarGraphMin, activeOverviewConfiguration.detailBarGraphMax()],
            range: [graphHeight, 0],
          })
        : indexScale,
    [activeOverviewConfiguration, graphHeight, indexScale],
  );

  const getValue = useCallback(
    (datum: OverviewDatum) => {
      const [metric] = getMetricAndLevel(
        datum,
        queryFilters.filters.selectedTargets,
        MetricMode.REDUCTION,
        ProcessMode.DURING,
        activeChartUnit,
        getGroup,
        allAssays,
      );
      return isNil(metric) ? undefined : indexScale(metric);
    },
    [queryFilters.filters.selectedTargets, getGroup, indexScale, activeChartUnit, allAssays],
  );

  return (
    <BaseChart
      width={trendChartSize.width}
      height={trendChartSize.height}
      indexScale={indexScale}
      labelScale={labelScale}
      Legend={GradientLegend}
      getValue={getValue}
      TooltipComponent={TooltipComponentForReduction}
    />
  );
}

interface GradientLegendProps {
  left: number;
  height: number;
  width: number;
}

function GradientLegend({ left, height, width }: GradientLegendProps) {
  const cornerRadius = theme.borders.radius.small;
  const { activeChartUnit, activeOverviewConfiguration } = useOverviewContext();

  return (
    <svg width={width} height={height} style={{ position: 'absolute', left }}>
      <defs>
        <clipPath id="gradient-legend-clippath">
          <rect
            x={0}
            y={positioning.margin.top / 2}
            width={width}
            height={height - positioning.margin.top - positioning.margin.bottom}
            rx={cornerRadius}
          />
        </clipPath>
        <LinearGradient id="linear-gradient-for-legend" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop
            offset="0%"
            stopColor={getMetricColor(
              activeOverviewConfiguration.detailBarGraphMin,
              MetricMode.REDUCTION,
              activeChartUnit,
            )}
          />
          <stop offset="50%" stopColor={getMetricColor(0, MetricMode.REDUCTION, activeChartUnit)} />
          <stop
            offset="100%"
            stopColor={getMetricColor(
              activeOverviewConfiguration.detailBarGraphMax(),
              MetricMode.REDUCTION,
              activeChartUnit,
            )}
          />
        </LinearGradient>
      </defs>
      <rect
        x={0}
        y={positioning.margin.top / 2}
        width={width}
        height={height - positioning.margin.top - positioning.margin.bottom}
        fill="url(#linear-gradient-for-legend)"
        clipPath="url(#gradient-legend-clippath)"
      />
    </svg>
  );
}
