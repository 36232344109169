import { scaleOrdinal } from '@visx/scale';

import { QueryParams } from '@resistapp/client/utils/url-manipulation';
import { friendlyEnvironmentType } from '@resistapp/common/environment-types';
import { getEnvironmentTypeOrWasteWater } from '@resistapp/common/utils';
import { WORLDMAP_BINS, WORLDMAP_COLORS } from './heatmap-bins';
import { Legend } from './legend';

import { useSearchParamsContext } from '@resistapp/client/contexts/search-params-context';
export function WorldmapLegend() {
  const domain = WORLDMAP_BINS;
  const range = WORLDMAP_COLORS;
  const { getQueryParams } = useSearchParamsContext();

  const type = getEnvironmentTypeOrWasteWater(getQueryParams(QueryParams.ENVIRONMENT_TYPE));
  const colorScale = scaleOrdinal<string, string>({ domain, range });
  const labelFormat = (label: string | number) => String(label);

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <strong>Median abundance</strong>
        <br />
        <strong>in {friendlyEnvironmentType(type).toLowerCase()},</strong>
        <br />
        <strong>relative to 16S rRNA</strong>
      </div>
      <Legend colorScale={colorScale} labelFormat={labelFormat} getOpacity={() => 1} />
    </>
  );
}
