import { useSearchParamsContext } from '@resistapp/client/contexts/search-params-context';
import { isAdmin } from '@resistapp/common/features';
import { useUser } from '../contexts/use-user-context';
import { ExperimentalMetrics } from '../data-utils/plot-data/build-experimental-metrics';
import { OtherParams } from '../utils/url-manipulation';

export function useExperimentalMetric() {
  const { getQueryParams } = useSearchParamsContext();
  const { user } = useUser();

  const experimental = getQueryParams(OtherParams.EXPERIMENTAL);

  return experimental && isAdmin(user) ? (experimental as keyof ExperimentalMetrics) : null;
}
