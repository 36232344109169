import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { FullProject } from '@resistapp/common/types';
import { GroupChildContainer } from '../../containers/group-child-container';
import { SettingsIcon } from '../../icons/settings-icon';
import { UndoIcon } from '../../icons/undo-icon';
import { ChartGroupContainer } from '../../plots/containers/chart-group-container';
import { GeneLegendFilter } from '../../plots/legends/gene-legend-filter';
import { SampleAndTypeSearch } from '../../plots/legends/sample-search';
import { theme } from '../../shared/theme';
import { AbsoluteSwitch } from './absolute-switch';

interface Props {
  style?: React.CSSProperties;
  sampling: FullProject;
  innerRef?: React.RefObject<HTMLDivElement>;
}

export function FilterBar(props: Props) {
  const { queryFilters } = useSampleDataContext();

  return (
    <Container
      ref={props.innerRef}
      className="test-filter-bar"
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        padding: '3px',
        ...props.style,
        // overflowX: 'auto', We can't add this, since it will hide select elements if done
      }}
    >
      <Grid
        height="100%"
        templateColumns={{ lg: 'repeat(3, 1fr) 160px', md: '1fr 1fr' }}
        style={{
          gridRowGap: '0px',
          gridColumnGap: '20px',
          width: '100%',
          paddingRight: '20px',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          paddingLeft: '57px',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            left: '20px',
            display: 'flex',
            paddingTop: '13px',
          }}
        >
          <SettingsIcon />
        </Box>
        <FilterChildContainer style={{ border: 0, backgroundColor: theme.colors.neutral50 }}>
          <GeneLegendFilter queryFilters={queryFilters} />
        </FilterChildContainer>
        <FilterChildContainer style={{ border: 0, backgroundColor: theme.colors.neutral50 }}>
          <SampleAndTypeSearch queryFilters={queryFilters} sampling={props.sampling} />
        </FilterChildContainer>
        <FilterChildContainer style={{ border: 0, backgroundColor: theme.colors.neutral50 }}>
          <AbsoluteSwitch queryFilters={queryFilters} />
        </FilterChildContainer>
        <FilterChildContainer
          onClick={queryFilters.resetFiltersStable}
          style={{
            minWidth: '145px',
            height: '40px',
            marginTop: '5px',
            marginLeft: '20px',
            width: 'max-content',
          }}
        >
          <Flex
            style={{
              gap: '10px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UndoIcon />
            Reset
          </Flex>
        </FilterChildContainer>
      </Grid>
    </Container>
  );
}

const FilterChildContainer = styled(GroupChildContainer)`
  height: 50px;
  margin: 0;
  padding: 0;
  align-content: center;
  background-color: white !important;
`;

const Container = styled(ChartGroupContainer)`
  box-shadow: 0px 2px 20px 0px rgba(41, 54, 81, 0.15);
`;
