import type { PartialDict } from '@resistapp/common/types';
import type { PossibleZoomableLevel } from '@resistapp/common/utils';

export type LevelOption = {
  value: `${string}-${number}`;
  label: string;
  country: string;
};

export type LevelsWithZoomableAreasOption = {
  label: string; // Country name
  options: LevelOption[];
};

export function getZoomableLevelsOptions(
  levelsWithZoomableAreas: PartialDict<PossibleZoomableLevel[]> | undefined,
): LevelsWithZoomableAreasOption[] {
  return Object.entries(levelsWithZoomableAreas || {}).map(([country, levels]) => ({
    label: country,
    options: levels
      ? levels
          .sort((a, b) => a.valueOf() - b.valueOf())
          .map(level => ({
            value: `${country}-${level}`,
            label: `${country}: ${level}`,
            country,
          }))
      : [],
  }));
}

export function getZoomableLevelsValues(
  allLevelsWithZoomableAreas: PartialDict<PossibleZoomableLevel[]> | undefined,
): LevelOption[] {
  return Object.entries(allLevelsWithZoomableAreas || {}).reduce<LevelOption[]>((acc, [country, options]) => {
    if (!options) {
      return acc;
    }
    acc.push(
      ...options.map(level => ({
        value: `${country}-${level}` as const,
        label: `${country}: ${level}`,
        country,
      })),
    );
    return acc;
  }, []);
}
