import { ListItem, UnorderedList } from '@chakra-ui/react';
import { AbunanceSelection } from '@resistapp/client/data-utils/filter-data/filter';
import { useNormalisationMode } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { isConsideredAbsolute, NormalisedValueName } from '@resistapp/common/normalisation-mode';
import { scaleOrdinal } from '@visx/scale';
import { BaseLegend } from './base-legend';
import { ALL_COLORS, getBinLabels, QUANTIFIED_ONLY_COLORS } from './heatmap-bins';
import { Legend } from './legend';

interface HeatmapLegendProps {
  setAbundanceMode: (mode: AbunanceSelection) => void;
  abundanceMode: AbunanceSelection;
  legendHeight: string;
  id?: string;
}

export function HeatmapLegend({ abundanceMode, setAbundanceMode, legendHeight, id }: HeatmapLegendProps) {
  const normalisationMode = useNormalisationMode();
  const bins = getBinLabels(normalisationMode, abundanceMode);
  const colors = abundanceMode === AbunanceSelection.QUANTIFIED_ONLY ? QUANTIFIED_ONLY_COLORS : ALL_COLORS;
  const colorScale = scaleOrdinal<string, string>({ domain: bins, range: colors });
  const labelFormat = (label: string | number) => String(label);
  const option = heatmapOptions.find(o => o.value === abundanceMode) as Option;
  const numberExplanation = isConsideredAbsolute(normalisationMode)
    ? 'The colors in the heatmap show an estimation of gene copy numbers.'
    : 'The colors in the heatmap show gene abundances relative to 16S rRNA gene.';
  const description = NormalisedValueName[normalisationMode];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
      }}
    >
      <BaseLegend
        id={id}
        header="Genes in detail"
        description={description}
        options={heatmapOptions}
        onChange={value => {
          setAbundanceMode(value as AbunanceSelection);
        }}
        defaultValue={option.value}
        TooltipContent={
          <UnorderedList>
            <ListItem>Heatmap colors correspond to ranges between logaritmic axis ticks on the box plot.</ListItem>
            <ListItem>{numberExplanation}</ListItem>
            <ListItem>Use this drop-down to focus on detected genes only.</ListItem>
            <ListItem>You can limit the genes further by the above gene group selection.</ListItem>
          </UnorderedList>
        }
      >
        <Legend colorScale={colorScale} labelFormat={labelFormat} getOpacity={() => 1} height={legendHeight} />
      </BaseLegend>
    </div>
  );
}

export const heatmapOptions: Option[] = [
  {
    label: 'All analysed genes',
    value: AbunanceSelection.ANALYSED,
  },
  {
    label: 'Detected and traces',
    value: AbunanceSelection.QUANTIFIED_AND_TRACES,
  },
  {
    label: 'Detected only',
    value: AbunanceSelection.QUANTIFIED_ONLY,
  },
];

interface Option {
  label: string;
  value: AbunanceSelection;
}
