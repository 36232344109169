import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { AbunanceSelection, Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { GeneGrouping, L2Target } from '@resistapp/common/assays';
import { FullProject } from '@resistapp/common/types';
import { useLayoutEffect, useRef } from 'react';
import { ChartGroupContainer } from '../containers/chart-group-container';
import { GeneLegend } from '../legends/gene-legend';
import { HeatmapLegend } from '../legends/heatmap-legend';
import { SampleLegend } from '../legends/sample-legend';
import { MultiPlot } from './multi-plot';

interface Props {
  project: FullProject;
  toggleGeneGroup: (group: L2Target | L2Target[], only: boolean) => void;
  toggleEnvironment: (envName: string | string[], only: boolean) => void;
  setGrouping: (grouping: GeneGrouping) => void;
  setAbundanceMode: (mode: AbunanceSelection) => void;
  filters: Filters;
}

const legendWidth = '210px';
export function BarBoxMap(props: Props) {
  const { setGrouping, toggleEnvironment, toggleGeneGroup, setAbundanceMode, filters, project } = props;
  const { plotData, setGraphAndLegendContainerWidth, graphAndLegendContainerWidth } = useResearchContext();
  const showSampleNumbers = true;
  const barBoxPlotRef = useRef<HTMLDivElement>(null);
  const legendBoxRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      const plotWidth = (barBoxPlotRef.current?.offsetWidth || 0) - (legendBoxRef.current?.offsetWidth || 0);
      setGraphAndLegendContainerWidth(plotWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!graphAndLegendContainerWidth && barBoxPlotRef.current && legendBoxRef.current) {
    const plotWidth = (barBoxPlotRef.current.offsetWidth || 2) - (legendBoxRef.current.offsetWidth || 1);
    setGraphAndLegendContainerWidth(plotWidth);
  }

  if (!plotData) {
    return null;
  }

  return (
    <ChartGroupContainer style={{ paddingBottom: '36px' }} className="test-bar-box-map">
      <ChartAndLegendContainer>
        <Flex flexDirection="row" ref={barBoxPlotRef}>
          <LegendContainer ref={legendBoxRef}>
            <GeneLegend
              id="research-view-detected-genes-legend"
              showOptions={false}
              filters={filters}
              setGrouping={setGrouping}
              availableGroups={plotData.geneGroups}
              header="Detected genes"
              description="Number of genes by class"
              toggleGeneGroup={toggleGeneGroup}
              legendHeight="270px"
            />
          </LegendContainer>
          <Box>
            <MultiPlot
              width={graphAndLegendContainerWidth}
              type="bar"
              chartId="research-view-detected-genes-chart"
              showSampleNumbers={showSampleNumbers}
              project={project}
            />
          </Box>
        </Flex>
      </ChartAndLegendContainer>
      <ChartAndLegendContainer>
        <Flex flexDirection="row">
          <LegendContainer>
            <SampleLegend
              id="research-view-samples-legend"
              filters={filters}
              toggleEnvironment={toggleEnvironment}
              project={project}
              legendHeight="350px"
              showSampleNumbers={showSampleNumbers}
            />
          </LegendContainer>
          <Box>
            <MultiPlot
              chartId="research-view-samples-chart"
              width={graphAndLegendContainerWidth}
              type="box"
              showSampleNumbers={showSampleNumbers}
              project={project}
            />
          </Box>
        </Flex>
      </ChartAndLegendContainer>
      <ChartAndLegendContainer>
        <Flex flexDirection="row">
          <LegendContainer>
            <HeatmapLegend
              id="research-view-gene-details-legend"
              abundanceMode={filters.abundances}
              setAbundanceMode={setAbundanceMode}
              legendHeight="170px"
            />
          </LegendContainer>
          <Box>
            <MultiPlot
              chartId="research-view-gene-details-chart"
              width={graphAndLegendContainerWidth}
              type="heat"
              showSampleNumbers={showSampleNumbers}
              project={project}
            />
          </Box>
        </Flex>
      </ChartAndLegendContainer>
    </ChartGroupContainer>
  );
}

const ChartAndLegendContainer = styled(Box)`
  padding: 15px;
  padding-top: 2px;
  padding-bottom: 0;
`;

const LegendContainer = styled.div`
  min-width: ${legendWidth};
  max-width: ${legendWidth};
  height: 270px;
`;
