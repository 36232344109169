import { useUser } from '@resistapp/client/contexts/use-user-context';
import { getUserProjectIds } from '@resistapp/common/access';
import { Feature, hasFeature, isAdmin } from '@resistapp/common/features';
import { demoProjectId } from '@resistapp/common/utils';
import { Navigate } from 'react-router-dom';
import { LoadingView } from '../components/states/loading';
import { WorldView } from './world-view/world-view';

export function LanderView() {
  const { user, userLoading } = useUser();
  if (userLoading) {
    return <LoadingView />;
  } else if (!user) {
    return <WorldView />;
  } else if (isAdmin(user)) {
    return <Navigate to={`/admin`} replace />;
  } else if (hasFeature(user, Feature.ARGI)) {
    const projectIds = getUserProjectIds(user);
    return <Navigate to={`/index/${projectIds[0]}`} replace />;
  } else if (hasFeature(user, Feature.RISK)) {
    const projectIds = getUserProjectIds(user);
    return <Navigate to={`/risk/${projectIds[0]}`} replace />;
  } else if (hasFeature(user, Feature.REDUCTION)) {
    const projectIds = getUserProjectIds(user);
    return <Navigate to={`/reduction/${projectIds[0]}`} replace />;
  } else {
    const projectIds = getUserProjectIds(user);
    if (projectIds.length) {
      return <Navigate to={`/research/${projectIds[0]}`} replace />;
    } else {
      return <Navigate to={`/research/${demoProjectId}`} replace />;
    }
  }
}
