import styled from '@emotion/styled';
import { InfoTooltipIcon } from '@resistapp/client/components/icons/info-tooltip-icon';
import { LegendInfoIcon } from '@resistapp/client/components/info-icons/legend-info-icon';
import { TutorialFooter } from '@resistapp/client/components/tooltips/tutorial-footer';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useTutorialContext } from '@resistapp/client/contexts/tutorial-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useTutorial } from '@resistapp/client/hooks/use-tutorial';
import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { L2Target } from '@resistapp/common/assays';
import { MetricMode } from '@resistapp/common/types';
import { trim } from 'lodash';
import { theme } from '../../../shared/theme';
import { ReferenceLineProps, useGeographicalReferenceLines } from './use-geographical-reference-lines';

export function TrendcharBottomLegend(props: {
  isMobile?: boolean;
  areAllAntibioticsSelected?: boolean;
  showData?: {
    quartileRange?: boolean;
    referenceLines?: boolean;
  };
}) {
  const { setTutorialChanged } = useTutorialContext();
  const { currentTutorialStep, tutorial, nextStep, prevStep, stopTutorial } = useTutorial('overview');
  const refLines = useGeographicalReferenceLines(undefined);
  const { queryFilters } = useSampleDataContext();
  const { metricMode, activeOverviewConfiguration } = useOverviewContext();
  const selectedAntibiotics = queryFilters.filters.selectedTargets;
  const allCurveLegens = getCurveLegends(
    setTutorialChanged,
    currentTutorialStep,
    tutorial.stepCount,
    nextStep,
    prevStep,
    stopTutorial,
    !props.showData?.referenceLines ? [] : refLines,
    selectedAntibiotics,
    metricMode,
    activeOverviewConfiguration,
  );
  const correctCurveLegens = props.showData?.quartileRange
    ? [...allCurveLegens]
    : allCurveLegens.filter(curve => curve.text !== 'Interquartile range (IQR)');

  return (
    <LegendBottomContainer>
      {correctCurveLegens.map(curve => (
        <LegendBottomGroups
          key={curve.text}
          style={{ display: !props.isMobile || curve.showInMobile ? 'flex' : 'none' }}
        >
          {curve.element}
          <div>{curve.text}</div>
          <div>{curve.tooltip}</div>
        </LegendBottomGroups>
      ))}
    </LegendBottomContainer>
  );
}

const LegendBottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.oldSpacing.xl};
`;

const LegendBottomGroups = styled.div`
  height: 20px;
  display: flex;
  gap: ${theme.oldSpacing.xs};
`;

const marginTop = '3px';
function getCurveLegends(
  setTutorialChanged: () => void,
  currentStep: number,
  stepCount: number,
  nextStep: () => void,
  prevStep: () => void,
  stopTutorial: () => void,
  refLines: ReferenceLineProps[],
  selectedAntibiotics: L2Target[],
  metricMode: MetricMode,
  activeOverviewConfiguration?: OverviewChartConfiguration,
) {
  const metricName = activeOverviewConfiguration?.trendChartBottomLegendName;
  return [
    ...refLines.map(line => ({
      element: (
        <svg width="30" height="20">
          <line x1="0" x2="30" y1="10" y2="10" {...line.lineProps} />
        </svg>
      ),
      text: line.text,
      tooltip: (
        <InfoTooltipIcon placement="top" style={{ padding: 0, paddingRight: theme.oldSpacing.xs }}>
          <div style={{ paddingBottom: '10px' }}>{line.tooltipText}</div>
        </InfoTooltipIcon>
      ),
      showInMobile: false,
      showTutorial: false,
      footer: undefined,
    })),
    {
      element: (
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            height: '2px',
            width: '30px',
            backgroundColor: theme.colors.neutral400,
            marginTop,
          }}
        />
      ),
      text: trim(`Site ${selectedAntibiotics.length === 1 ? '' : metricName}`),
      tooltip: (
        <LegendInfoIcon
          metricMode={metricMode}
          infoIconProps={{ placement: 'top' }}
          activeOverviewConfiguration={activeOverviewConfiguration}
        />
      ),
    },
    {
      showInMobile: true,
      showTutorial: true,
      element: (
        <div
          style={{
            height: '100%',
            width: '35px',
            backgroundColor: theme.colors.transparentBlue,
            borderRadius: '5px',
            marginTop,
          }}
        />
      ),
      text: 'Interquartile range (IQR)',
      tooltipElement: (
        <InfoTooltipIcon placement="top" style={{ padding: 0, paddingRight: theme.oldSpacing.xs }}>
          <div style={{ paddingBottom: '10px' }}>
            <IqrDescription />
          </div>
        </InfoTooltipIcon>
      ),
      footer: (
        <TutorialFooter
          currentStep={currentStep}
          stepCount={stepCount}
          nextStep={() => {
            setTutorialChanged();
            nextStep();
          }}
          prevStep={() => {
            setTutorialChanged();
            prevStep();
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }, 0);
          }}
          stopTutorial={() => {
            setTutorialChanged();
            stopTutorial();
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }, 0);
          }}
        />
      ),
    },
  ];
}

function IqrDescription() {
  return (
    <span>
      <i>Interquartile Range (IQR)</i> represents the middle 50% of detected genes, showing the spread between the 25th
      percentile (Q1) and the 75th percentile (Q3). This range illustrates the variability between different detected
      genes.
    </span>
  );
}
