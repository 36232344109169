import { Flex } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getGenesAndCopyNumbers,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { useContainerWidth } from '@resistapp/client/hooks/use-container-width';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { MetricMode } from '@resistapp/common/types';
import { MetricTitle } from '../../shared/overview-metric-title';
import { CopyNumberBarGraph } from '../copy-number-bar-graph';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteGeneDetailBox } from '../site-gene-detail-box';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';
import { detailGraphHeight } from './site-details-utils';

const graphMargins = 26;

export function PathogenDetails({ containerRef }: SiteDetailsBaseProps) {
  const { selectedSiteDatum, metricMode, activeOverviewConfiguration, effectiveSiteDetailsProcessMode } =
    useOverviewContext();
  const { allAssays, getGroup } = useAssayContext();
  const containerWidth = useContainerWidth(containerRef);

  if (!selectedSiteDatum) {
    return null;
  }

  const minorTarget = MinorTarget.PATHOGEN;
  const { detectedCount, analysedCount } = getNumDetectedAndAnalysedAssays(
    selectedSiteDatum,
    minorTarget,
    metricMode,
    effectiveSiteDetailsProcessMode,
    getGroup,
  );
  const width = (containerWidth || 130) - graphMargins;
  const title = `Pathogen markers`;

  if (detectedCount === 0) {
    return (
      <SiteDetailContainer ref={containerRef}>
        <SiteDetailTitle title={title} explanation={`No pathogen associated genes detected`} />
        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
          <SiteGeneDetailBox target={minorTarget} />
        </Flex>
      </SiteDetailContainer>
    );
  } else {
    const genesAndCopyNumbers = getGenesAndCopyNumbers(
      selectedSiteDatum,
      minorTarget,
      metricMode,
      effectiveSiteDetailsProcessMode,
      getGroup,
    );
    const friendlyGenesAndNumbers = activeOverviewConfiguration.getFriendlyNumber(
      genesAndCopyNumbers,
      selectedSiteDatum,
      minorTarget,
      allAssays,
      getGroup,
    );
    const _inRawOrEffluentSamples = get_inRawOrEffluentSamplesString(
      selectedSiteDatum,
      effectiveSiteDetailsProcessMode,
    );

    const unit =
      metricMode === MetricMode.REDUCTION ? (
        <MetricTitle noAntibiotic={true} plural={detectedCount > 1} />
      ) : (
        'Copy number'
      );
    const explanation = _inRawOrEffluentSamples ? (
      <span>
        {unit} of independently detected pathogen marker genes, {_inRawOrEffluentSamples}
      </span>
    ) : (
      <span>{unit} of independently detected pathogen marker genes</span>
    );
    return (
      <SiteDetailContainer ref={containerRef}>
        <SiteDetailTitle title={title} explanation={explanation} counts={{ detectedCount, analysedCount }} />
        {containerWidth && (
          <CopyNumberBarGraph
            genesAndNumbers={friendlyGenesAndNumbers}
            width={width}
            height={detailGraphHeight}
            events={false}
            metricMode={metricMode}
            activeOverviewConfiguration={activeOverviewConfiguration}
            italic
          />
        )}
      </SiteDetailContainer>
    );
  }
}
