import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useMetricAndLevel } from '@resistapp/client/contexts/use-overview-context/hooks/use-metric-and-level';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { AllProjectEnvironmentTypesGroup } from '@resistapp/common/comparable-env-groups';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { MouseEventHandler } from 'react';
import { MarkerPointerArrow } from '../../arrows/arrow';
import { CloseIconCircle } from '../../icons/close-icon';
import { DownRightIcon, RightIcon, UpRightIcon } from '../../icons/diaconal-arrow-icons';
import { EnvTypeIcon } from '../../icons/env-type-icons';
import { getChangeIndicatorColor, OverviewMetricLabel } from '../../shared/overview-metric-label';
import { theme } from '../../shared/theme';
import { getMarkerOffset } from './marker-utils';

export type Positions = 'top' | 'bottom' | 'left' | 'right' | 'center' | 'chart';
const directionStyles = {
  bottom: {
    position: 'absolute',
    flexDirection: 'column-reverse',
  },
  top: {
    position: 'absolute',
    flexDirection: 'column',
  },
  center: {
    position: 'absolute',
    flexDirection: 'column',
  },
  left: {
    position: 'absolute',
    flexDirection: 'row',
  },
  right: {
    position: 'absolute',
    flexDirection: 'row-reverse',
  },
  chart: {
    position: 'absolute',
    flexDirection: 'column',
  },
} as const satisfies { [index in Positions]: { position: string; flexDirection: string } };

interface Props {
  overviewDatum: OverviewDatum;
  position: Positions;
  pinned?: boolean;
  onClick?: (close?: boolean) => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
}

export function ProcessMarker({ onMouseEnter, overviewDatum, position, pinned = false, onClick = () => {} }: Props) {
  const { metricMode, processMode, activeChartUnit, selectedEnvironmentTypeGroup } = useOverviewContext();
  const hasPreciseCoordinates = Boolean(overviewDatum.lat);

  const { metric, level: metricLevel } = useMetricAndLevel(overviewDatum);
  const { metric: metricAfter } = useMetricAndLevel(overviewDatum, {
    processMode: ProcessMode.AFTER,
  });
  const { metric: metricBefore } = useMetricAndLevel(overviewDatum, {
    processMode: ProcessMode.BEFORE,
  });

  // TODO see that marker behaves as spec'd: https://docs.google.com/spreadsheets/d/1SDXnN0LI8_hNqwNgDlpKJtCFL_JiPif_5KxBQgW2e90/edit?gid=0#gid=0

  // TODO fix clicking into siteview for blomminmäkin in treated_WW risk mode
  // TODO fix 2-fold change graph in TREATED_WW fold change mode (should not show data)

  // useOverviewContext must be used within a OverviewContextProvider

  const changeInMetric =
    isNil(metricAfter) || isNil(metricBefore)
      ? null
      : metricAfter === metricBefore
        ? 0
        : metricAfter > metricBefore
          ? 1
          : -1;

  const { width, height } = getMarkerSize();
  const offset = getMarkerOffset(position, width, height, !hasPreciseCoordinates);
  const style = {
    ...directionStyles[position],
    top: `${offset.y}px`,
    left: `${offset.x}px`,
  };

  return (
    <ProcessMarkerContainer
      onMouseEnter={onMouseEnter}
      width={width}
      height={height}
      style={{
        display: 'flex',
        ...style,
      }}
    >
      {pinned && (
        <CloseIconCircle
          onClick={() => {
            onClick(true);
          }}
          style={{
            zIndex: theme.zIndexes.closeIcon,
          }}
        />
      )}
      <ProcessMarkerBox
        className="ProcessMarkerBox"
        pinned={pinned}
        onClick={() => {
          onClick();
        }}
      >
        <IndexNameContainer>
          {overviewDatum.environment.subtype && (
            <MarkerTitleContainer>
              <EnvTypeIcon
                envSubtype={overviewDatum.environment.subtype}
                style={{
                  height: '90%',
                  paddingRight: theme.spacing[1],
                  paddingLeft: theme.spacing[1],
                }}
              />
            </MarkerTitleContainer>
          )}
          <Text
            noOfLines={2}
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="12px"
            fontWeight={theme.fontWeight.heavy}
            width="100%"
            textAlign="center"
            sx={{
              wordBreak: 'break-all',
              whiteSpace: 'normal',
            }}
          >
            {overviewDatum.environment.name}
          </Text>
        </IndexNameContainer>
        <MetricContainer>
          {processMode === ProcessMode.AFTER && !isNil(metricBefore) && (
            <>
              {!isNil(metric) ? (
                <BeforeContainer change={changeInMetric ?? 0} metricMode={metricMode}>
                  <ResistanceIndexBeforeContainer id="ResistanceIndexBeforeContainer">
                    {!isNil(metricBefore) &&
                      metricBefore.toFixed(
                        metricMode === MetricMode.ARGI || metricMode === MetricMode.REDUCTION ? 1 : 0,
                      )}
                  </ResistanceIndexBeforeContainer>
                  {!isNil(changeInMetric) && (
                    <ArrowContainer>
                      {changeInMetric < 0 ? (
                        <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                      ) : changeInMetric > 0 ? (
                        <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                      ) : (
                        <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                      )}
                    </ArrowContainer>
                  )}
                </BeforeContainer>
              ) : (
                <OverviewMetricLabel
                  id="process-marker-before-metric"
                  level={metricLevel}
                  metricMode={metricMode}
                  metric={metricBefore}
                  activeChartUnit={activeChartUnit}
                  style={{
                    backgroundColor:
                      selectedEnvironmentTypeGroup === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
                        ? undefined
                        : 'white',
                    color:
                      selectedEnvironmentTypeGroup === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
                        ? undefined
                        : theme.colors.neutral500,
                    width: '100%',
                  }}
                />
              )}
            </>
          )}
          {((processMode === ProcessMode.AFTER && isNil(metricBefore)) ||
            (processMode === ProcessMode.BEFORE && isNil(metricAfter)) ||
            (!isNil(metricBefore) && !isNil(metricAfter)) ||
            metricMode === MetricMode.REDUCTION) && (
            <OverviewMetricLabel
              id="process-marker-metric-label"
              level={metricLevel}
              metricMode={metricMode}
              metric={metric}
              activeChartUnit={activeChartUnit}
              style={{
                backgroundColor:
                  processMode === ProcessMode.AFTER &&
                  isNil(metricBefore) &&
                  selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
                    ? 'white'
                    : undefined,
                color:
                  processMode === ProcessMode.AFTER &&
                  isNil(metricBefore) &&
                  selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
                    ? theme.colors.neutral500
                    : undefined,
                width: '100%',
              }}
            />
          )}
          {processMode === ProcessMode.BEFORE && !isNil(metricAfter) && (
            <>
              {!isNil(metric) ? (
                <AfterContainer change={changeInMetric ?? 0}>
                  {!isNil(changeInMetric) && (
                    <ArrowContainer>
                      {changeInMetric < 0 ? (
                        <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                      ) : changeInMetric > 0 ? (
                        <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                      ) : (
                        <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                      )}
                    </ArrowContainer>
                  )}
                  <ResistanceIndexAfterContainer id="ResistanceIndexAfterContainer">
                    {!isNil(metricAfter) &&
                      metricAfter.toFixed(
                        metricMode === MetricMode.ARGI || metricMode === MetricMode.REDUCTION ? 1 : 0,
                      )}
                  </ResistanceIndexAfterContainer>
                </AfterContainer>
              ) : (
                <OverviewMetricLabel
                  id="process-marker-after-metric"
                  level={metricLevel}
                  metricMode={metricMode}
                  metric={metricAfter}
                  activeChartUnit={activeChartUnit}
                  style={{
                    backgroundColor:
                      selectedEnvironmentTypeGroup === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
                        ? undefined
                        : 'white',
                    color:
                      selectedEnvironmentTypeGroup === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
                        ? undefined
                        : theme.colors.neutral500,
                    width: '100%',
                  }}
                />
              )}
            </>
          )}
        </MetricContainer>
      </ProcessMarkerBox>
      {overviewDatum.lat && position !== 'chart' && position !== 'center' && (
        <MarkerPointerArrow
          direction={position === 'top' ? 'down' : position === 'bottom' ? 'up' : position}
          borderColor={pinned ? theme.colors.blue500 : undefined}
        />
      )}
    </ProcessMarkerContainer>
  );
}

export function getMarkerSize() {
  return {
    width: 104,
    height: 90,
    chartOffset: 30,
  };
}

const ProcessMarkerContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  cursor: pointer;
  filter: ${theme.shadows.normal.filter};
  font-family: ${theme.fontFamily.baseVariable};
`;

const ProcessMarkerBox = styled.div<{ pinned?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.spacing[2]} 6px 0;
  border-radius: ${theme.borders.radius.small};
  background-color: white;
  border: ${props => (props.pinned ? theme.borders.selected : 'none')};
  color: ${theme.colors.bodyGray};
`;

const AfterContainer = styled.div<{ change: number }>`
  min-width: 32px;
  display: flex;
  flex-direction: ${props => (props.change === 1 ? 'column-reverse' : 'column')};
  align-items: center; // Changed from flex-start to center
  padding-left: ${theme.spacing[1]};
`;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: ${theme.spacing[0.5]};
`;

const IndexNameContainer = styled.div`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  min-height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: normal;
  color: ${theme.colors.neutral700};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.heavy};
  width: 100%;
  word-break: break-all;
`;

const MarkerTitleContainer = styled.div<{ pinned?: boolean }>`
  padding-right: 2px;
  display: flex;
  height: 30px;
  margin-left: -${theme.spacing[1]};
  align-items: center;
  font-size: ${theme.fontSize.h1};
  font-weight: ${theme.fontWeight.heavy};
`;

const ArrowContainer = styled.div`
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BeforeContainer = styled.div<{ change: number; metricMode: MetricMode }>`
  min-width: 32px;
  display: flex;
  flex-direction: ${props =>
    props.metricMode === MetricMode.RISK
      ? props.change === 1
        ? 'column-reverse'
        : 'column'
      : props.change === 1
        ? 'column-reverse'
        : 'column'};
  align-items: center;
  padding-right: ${theme.spacing[1]};
`;

const ResistanceIndexAfterContainer = styled.div`
  display: flex;
  justify-content: center; // Added to center the text
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.heavy};
  color: ${theme.colors.neutral500};
  min-height: 20px;
  width: 100%; // Added to ensure full width
`;

const ResistanceIndexBeforeContainer = styled.div`
  display: flex;
  justify-content: center; // Added to center the text
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.heavy};
  color: ${theme.colors.neutral500};
  min-height: 20px;
  width: 100%; // Added to ensure full width
`;
