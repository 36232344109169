import { chain, isNil, keys, max, mean, sum } from 'lodash';
import { antibioticL2Targets, GetGroup, L2Target } from '../assays';
import { riskClassByOneHealthAssay } from '../assays-temp-96-gene-minor-targets';
import { FullAbundance, StrippedFullAbundance } from '../types';
import { filterDetected } from '../utils';
import { filterTargetAbundances } from './abundance-stats';

export enum IndexVersion {
  ARI = 'ARI',
  ARGI = 'ARGI',
  RISK = 'RISK',
}

export enum Aggregation {
  GEOMETRIC = 'GEOMETRIC',
  QUADRATIC = 'QUADRATIC',
  ARITHMETIC = 'ARITHMETIC', // non-log space
  ARITHMETIC_MAX = 'ARITHMETIC_MAX',
  KARINA = 'KARINA', // non-log space
  MAX = 'MAX',
  MEDIAN = 'MEDIAN',
}

export enum Scope {
  DETECTED = 'DETECTED',
  ANALYSED = 'ANALYSED',
}

export function getRiskScore(
  abundances: FullAbundance[] | undefined,
  antibiotic: L2Target[] | undefined,
  getGroup: GetGroup,
) {
  return (
    calcExperimentalRiskScore(
      abundances,
      Scope.ANALYSED,
      IndexVersion.RISK,
      Aggregation.KARINA,
      antibiotic,
      getGroup,
    ) ?? null
  );
}

/**
 * Calculate risk score as per https://doi.org/10.1016/j.jhazmat.2021.127621
 */
export function calcExperimentalRiskScore(
  abundances: StrippedFullAbundance[] | undefined,
  scope: Scope,
  index: IndexVersion,
  aggregation: Aggregation,
  antibiotic: L2Target[] | undefined,
  getGroup: GetGroup,
): number | undefined {
  if (index !== IndexVersion.RISK) {
    throw new Error();
  }

  if (!abundances?.length) {
    return undefined;
  }

  const inScopeAbundances = getAbundancesInScope(abundances, 'absolute', scope, false, getGroup, antibiotic);

  if (aggregation === Aggregation.ARITHMETIC_MAX) {
    return chain(inScopeAbundances)
      .groupBy(a => getGroup(a.assay))
      .mapValues(values => calcExperimentalRiskScore(values, scope, index, Aggregation.MAX, antibiotic, getGroup))
      .values()
      .mean()
      .value();
  }

  const inScopeAssays = chain(inScopeAbundances)
    .map(a => a.assay)
    .uniq()
    .value();
  const inScopeWeights = inScopeAssays.map(a => getRiskWeight(a));
  if (!inScopeAbundances.length || !inScopeWeights.length) {
    return undefined;
  }
  const weightNormaliser = inScopeWeights.length / sum(inScopeWeights);
  const weights = inScopeAbundances.map(a => getRiskWeight(a.assay));
  const inScopeValues = getValues(inScopeAbundances, 'absolute', scope);

  // Karina's Step 2 - Scaling
  // - minArgCopyNumber = y_min = sumMin = 0
  // - Approximate global 'y_max' copy numbers in 67 One Health package ARGs across approved projects as of 2024-10
  //   - maxArgCopyNumber: Top 95th percentile signle ARG copy number from db (normalised as if all samples had 67 ARGs)
  //   - maxSum: Top 95th percentile sample sum of all ARG copy numbers from db
  //     (ballpark max for the sum of in scope ARGs in the worst sample globally, best effort normalised as if all samples had 67 ARGs)
  //   - Based on ~6000 samples (see karina-normalisation.ts)

  const maxArgCopyNumber = 2_843_730;
  const maxSampleSum = 83_182_411;
  const globalSumNormaliser = maxArgCopyNumber / maxSampleSum;
  const numOneHealthGenes = keys(riskClassByOneHealthAssay).length;
  const numAnalysedValues = inScopeAbundances.length;
  const numArgNormaliser = numAnalysedValues ? numOneHealthGenes / numAnalysedValues : 1;
  const scaledValues = inScopeValues.map(v => Math.min(1, Math.max(0, v / maxArgCopyNumber)));
  // TODO discuss with Erik                            ^------------------^
  // - maxArgCopyNumber caps the individual ARG copy number to the top 5% highest abs abundance in the database globally
  // - As long as the normalised gene copy number does not get capped to 1 its effect will be reversed on line 109 below
  // - The other option would be to apply the [0, 1] limit only as the last step where we'd only divide by maxSampleSum
  // - If you think that there is no biological sense in limiting the effect of extereme outlier genes like this,
  //   either based on the paper or otherwise, then we can probably remove maxArgCopyNumber
  //

  switch (aggregation) {
    case Aggregation.GEOMETRIC:
      return ratioToPercentage(geometricMean(scaledValues));
    case Aggregation.MAX:
      return ratioToPercentage(max(scaledValues) || 0);
    case Aggregation.ARITHMETIC:
      return ratioToPercentage(mean(scaledValues) || 0);
    case Aggregation.KARINA: {
      const weightedValues = scaledValues.map((v, i) => weights[i] * v);
      const avgScaledValues = sum(weightedValues) * numArgNormaliser * weightNormaliser;
      const normalisedSum = avgScaledValues * globalSumNormaliser;
      return ratioToPercentage(normalisedSum);
    }
    default:
      throw new Error('Unknown aggregation');
  }
}

export function getAbundancesInScope(
  abundances: StrippedFullAbundance[],
  key: 'relative' | 'absolute',
  scope: Scope,
  allowAnyGenes: boolean,
  getGroup: GetGroup,
  antibiotic?: L2Target[],
) {
  const correctTargets = antibiotic ? antibiotic : antibioticL2Targets;
  const filteredAbundances = antibiotic ? filterTargetAbundances(abundances, correctTargets, getGroup) : abundances;

  return scope === Scope.DETECTED
    ? filterDetected(filteredAbundances) // filterDetected leaves some null bio replica's whose brothers were not null
        .filter(a => !isNil(a[key]))
        .filter(a => allowAnyGenes || riskClassByOneHealthAssay[a.assay])
    : filteredAbundances.filter(a => allowAnyGenes || riskClassByOneHealthAssay[a.assay]);
}

export function getValues(abundances: StrippedFullAbundance[], key: 'relative' | 'absolute', scope: Scope) {
  return scope === Scope.DETECTED
    ? abundances.map(abundance => abundance[key] as number)
    : abundances.map(a => a[key] || 0);
}

function ratioToPercentage(ratio: number): number {
  return Math.min(100, Math.max(0, 100 * ratio));
}

export function geometricMean(values: number[]): number {
  const product = Math.abs(values.reduce((acc, value) => acc * value, 1));
  return Math.pow(product, 1 / values.length);
}

export function getRiskWeight(assay: string) {
  const riskClass = riskClassByOneHealthAssay[assay];
  switch (riskClass) {
    case 'I':
      return 1;
    case 'II':
      return 0.75;
    case 'III':
      return 0.5;
    case 'IV':
      return 0.25;
    case undefined:
      throw Error(`Unexpected assay in risk weighting: ${assay}`);
  }
}
