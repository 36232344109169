import { z } from 'zod';

// ----- TARGETS
// To modify targets, modify at least this section, assays_.json, targetPalette, analysis and Customer Template.

export type L1Target = keyof typeof L1Targets;
export enum L1Targets {
  REFERENCE = 'REFERENCE',
  ARG = 'ARG',
  TAXONOMIC = 'TAXONOMIC',
  GENE_MOBILITY_MARKER = 'GENE_MOBILITY_MARKER',
  OTHER = 'OTHER',
}
export const zL1Targets = z.nativeEnum(L1Targets);

export type L2Target = keyof typeof L2Targets;
export enum L2Targets {
  MGE = 'MGE',
  INTEGRONS = 'INTEGRONS',
  BETA_LACTAM = 'BETA_LACTAM',
  VANCOMYCIN = 'VANCOMYCIN',
  MDR = 'MDR',
  TRIMETHOPRIM = 'TRIMETHOPRIM',
  PHENICOL = 'PHENICOL',
  QUINOLONE = 'QUINOLONE',
  SULFONAMIDE = 'SULFONAMIDE',
  TETRACYCLINE = 'TETRACYCLINE',
  AMINOGLYCOSIDE = 'AMINOGLYCOSIDE',
  MLSB = 'MLSB',
  OTHER_RESISTANCE_MARKER = 'OTHER_RESISTANCE_MARKER',
  PATHOGEN_MARKER = 'PATHOGEN_MARKER',
  OTHER_TAXONOMIC_MARKER = 'OTHER_TAXONOMIC_MARKER',
  OTHER_MICROBIAL_MARKER = 'OTHER_MICROBIAL_MARKER',
  SIXTEENS_RRNA = 'SIXTEENS_RRNA',
}
export const zL2Targets = z.nativeEnum(L2Targets);

export const sixteenS = L2Targets.SIXTEENS_RRNA as const satisfies L2Target;

export const l1TargetByL2: Record<L2Target, L1Target> = {
  SIXTEENS_RRNA: L1Targets.REFERENCE,
  OTHER_TAXONOMIC_MARKER: L1Targets.TAXONOMIC,
  PATHOGEN_MARKER: L1Targets.TAXONOMIC,
  INTEGRONS: L1Targets.GENE_MOBILITY_MARKER,
  MGE: L1Targets.GENE_MOBILITY_MARKER,
  MDR: L1Targets.ARG,
  MLSB: L1Targets.ARG,
  PHENICOL: L1Targets.ARG,
  QUINOLONE: L1Targets.ARG,
  VANCOMYCIN: L1Targets.ARG,
  BETA_LACTAM: L1Targets.ARG,
  SULFONAMIDE: L1Targets.ARG,
  TETRACYCLINE: L1Targets.ARG,
  TRIMETHOPRIM: L1Targets.ARG,
  AMINOGLYCOSIDE: L1Targets.ARG,
  OTHER_MICROBIAL_MARKER: L1Targets.OTHER,
  OTHER_RESISTANCE_MARKER: L1Targets.OTHER,
};

// --- GENE GROUPS
export interface AssayInfo {
  assay: string;
  gene: string;
  antibiotic?: string; // levelOne === 'ARG'
  l2Target: L2Target; // levelTwo majorGroup
  l1Target: L1Target; // levelOne topGroup
  mge?: string; // levelThree: Transposase, Insertion sequence, Plasmid incompatibility, Plasmid replication, Bacteriophage, Phage related lysozyme, Mobilization protein, Conjugal transfer protein
  carbapenem?: string; // levelFive - sub-classes of a single antibiotic
  [sixteenS]?: string;
  minMeltingTemperature?: number; // Only included for admins and analysis
  maxMeltingTemperature?: number; // Only included for admins and analysis
}

export const nonSixteenSL2Targets: L2Target[] = Object.values(L2Targets).filter(t => t !== sixteenS);
export const antibioticL2Targets: L2Target[] = Object.keys(L2Targets).filter(
  l2Target => l1TargetByL2[l2Target as L2Target] === L1Targets.ARG,
) as L2Target[];

export type GeneGrouping = 'l2Target' | 'antibiotic' | 'carbapenem' | 'mge' | 'SIXTEENS_RRNA';
export const selectableGeneGroups: GeneGrouping[] = ['l2Target', 'antibiotic', 'carbapenem', 'mge'];

export const carbapenem = 'Carbapenem' as const;
export const groupingName = {
  l2Target: 'All genes classes',
  antibiotic: 'All antibiotics',
  carbapenem,
  mge: 'MGEs',
  [sixteenS]: '16S rRNA',
  gene: '',
  assay: '',
};

export interface GroupingOption {
  label: string;
  value: GeneGrouping;
}

export const defaultGroupingOptions: GroupingOption[] = selectableGeneGroups.map(value => ({
  label: groupingName[value],
  value,
}));

export type GeneGroups = Record<string, Array<L2Targets | string>>;
export type GetGroup = (
  geneOrAssayOrTarget: string,
  grouping?: 'l2Target' | 'l1Target' | 'carbapenem' | 'mge' | 'SIXTEENS_RRNA' | 'antibiotic',
) => string | undefined;
