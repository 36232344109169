import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { getKeyPressesFromMouseClick } from '@resistapp/client/utils/general';
import { L2Targets } from '@resistapp/common/assays';
import { friendlyL2Target } from '@resistapp/common/friendly';
import { LegendOrdinal } from '@visx/legend';
import { ScaleOrdinal } from 'd3-scale';
import { useEffect, useRef, useState } from 'react';
import { noTextSelection } from '../../shared/theme';

const gradientPadding = '20px';

interface Props {
  colorScale: ScaleOrdinal<string, string>;
  labelFormat: (s: string | number) => string;
  getOpacity: (s: string) => number;
  onClick?: (label: string, keys: KeysPressOptions) => void;
  noColor?: boolean;
  height?: string;
}

export function Legend({ noColor, colorScale, labelFormat, getOpacity, onClick, height }: Props) {
  const legendGlyphSize = 14;
  const legendRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      const current = legendRef.current;
      if (current) {
        setIsScrollable(current.scrollHeight > current.clientHeight);
      }
    };

    const handleScroll = () => {
      const current = legendRef.current;
      if (current) {
        setIsScrollable(
          current.scrollHeight > current.clientHeight &&
            current.scrollTop + current.clientHeight < current.scrollHeight,
        );
      }
    };

    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    legendRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', checkScrollable);
      legendRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <LegendOrdinal scale={colorScale} labelFormat={labelFormat}>
        {labels => (
          <div
            ref={legendRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height,
            }}
            className="download_legend-labels"
          >
            {labels.map((label, i) => {
              const opacity = getOpacity(label.text);
              return (
                <LegendLabel
                  key={`sample-legend-quantile-${i}`}
                  onClick={e => {
                    if (onClick) {
                      onClick(label.text, getKeyPressesFromMouseClick(e));
                    }
                  }}
                  style={{ opacity, cursor: onClick ? 'pointer' : undefined }}
                >
                  {!noColor && (
                    <LegendSvg width={legendGlyphSize} height={legendGlyphSize} style={{ borderRadius: '2px' }}>
                      <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                    </LegendSvg>
                  )}
                  <LegendText>
                    {/* TODO FIX ME, THIS GENERIC COMPONENT SHOULD NOT HAVE TARGET SPECIFIC LOGIC (AND onClick SHOULD PROABBLY NOT DEAL WITH label.text SO WE COULD friendlyfy it)*/}
                    {label.text in L2Targets ? friendlyL2Target(label.text as L2Targets) : label.text}
                  </LegendText>
                </LegendLabel>
              );
            })}
          </div>
        )}
      </LegendOrdinal>
      {isScrollable && <BottomGradientContainer className="download_legend-labels-bottom-gradient" />}
    </>
  );
}

const BottomGradientContainer = styled.div`
  position: relative;
  height: ${gradientPadding};
  top: -${gradientPadding};
  background-image: linear-gradient(180deg, rgba(100, 100, 100, 0), white);
`;

export const LegendSvg = styled.svg`
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: ${oldTheme.spacing.xxs};
`;

export const LegendLabel = styled.div`
  height: 18px;
  width: 100%;
  white-space: nowrap;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
`;

export const LegendText = styled.span`
  ${noTextSelection}
  line-height: 18px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export type KeysPressOptions = {
  ctrl: boolean;
  shift: boolean;
};
