import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { References, useScienceReference } from '@resistapp/client/hooks/use-science-reference';
import { riskClassByOneHealthAssay } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { keys } from 'lodash';
import { InfoTooltipIcon } from '../icons/info-tooltip-icon';
import { MetricTitle } from '../shared/overview-metric-title';
import { theme } from '../shared/theme';
import { InfoIconProps } from './info-icons-general';

export function RiskScoreInfoIcon(props: InfoIconProps) {
  const style = props.style || {};
  const placement = props.placement || 'bottom';

  return (
    <InfoTooltipIcon
      header={props.header}
      placement={placement}
      style={{
        ...style,
        padding: 0,
        paddingRight: theme.oldSpacing.xs,
      }}
      isForceOpen={props.isForceOpen}
      highlight={props.isForceOpen}
      footer={props.tutorialFooter}
    >
      <RiskScoreTooltipContent tutorialActive={!!props.header} />
    </InfoTooltipIcon>
  );
}

interface ContentProps {
  tutorialActive?: boolean;
}

export function RiskScoreTooltipContent(props: ContentProps) {
  const numArgs = keys(riskClassByOneHealthAssay).length;
  const boldOrNormal = props.tutorialActive ? 'normal' : 'bold';
  const { isOneHealthProject } = useOverviewContext();
  const panelDescription = isOneHealthProject
    ? `a standardised set of ${numArgs} clinically relevant genes in Resistomap's OneHealth Panel`
    : `a custom set of ${numArgs} clinically relevant genes`;

  const [Ref1, Ref1Num] = useScienceReference({
    number: '1',
    href: 'https://doi.org/10.1016/j.jhazmat.2021.127621',
    text: 'SG Goh et al., 2022, A new modelling framework for assessing the relative…',
  });

  const [Ref2, Ref2Num] = useScienceReference({
    number: '2',
    href: 'https://doi.org/10.1038/s41467-021-25096-3',
    text: 'AN Zhang et al., 2021, An omics-based framework for assessing the health risk…',
  });

  return (
    <div>
      <div>
        <span style={{ fontWeight: boldOrNormal }}>
          <i>
            <MetricTitle noBold noColor />
          </i>
        </span>{' '}
        indicates the overall health risk associated with Antibiotic Resistance Genes (ARGs).
      </div>
      <div style={{ marginTop: 12 }}>
        <div>
          The score ranges from 0 to 100 and is calculated as a weighted sum of copy numbers of {panelDescription},
          relative to the sum in most polluted samples among 6,000 natural and wastewater samples in the Resistomap
          database. For sites with both influent and effluent sampling, effluent risk score is shown as the primary
          score for the site.
          <Ref1Num />
        </div>
        <div style={{ marginTop: 12 }}>
          Clinical health risk is estimated by classifying the ARGs into four risk categories based on three criteria:{' '}
          <i>Enrichment in human-associated environments</i>, <i>gene mobility</i>, and <i>host pathogenicity</i>.
          <Ref2Num />
        </div>
        <div style={{ marginTop: 12 }}>
          The risk score is 0 for a clean spring water sample with no quantifiable ARGs. A risk score of 100 indicates
          risk levels similar to those of the most polluted hospital wastewater samples.
        </div>
      </div>
      <References style={{ marginTop: 25, marginLeft: -10, marginBottom: props.tutorialActive ? -5 : 15 }}>
        <Ref1 />
        <Ref2 />
      </References>
    </div>
  );
}
