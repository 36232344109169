import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { L2Target, L2Targets } from '@resistapp/common/assays';
import { ReactNode, RefObject } from 'react';
import { theme } from '../../shared/theme';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteGeneDetailBox } from '../site-gene-detail-box';

export const SiteDetailContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  ${theme.addBorder(300, 'normal')};
  padding: ${theme.spacing[2]} ${theme.spacing[6]} ${theme.spacing[4]} ${theme.spacing[4]};
  background-color: white;
`;

export function GeneralSiteDetails({ containerRef, title, explanation, target, id }: SiteDetailsFullProps) {
  return (
    <SiteDetailContainer ref={containerRef} key={`site-detail-box-${id}`} className="element-general-site-details">
      <SiteDetailTitle title={title} explanation={explanation} />
      <SiteGeneDetailBox target={target} />
    </SiteDetailContainer>
  );
}

export function formatGeneMutationSuffixes(gene: string) {
  return gene.replace(/^(.+)[_][0-9]{1}$/g, ' $1');
}

export interface SiteDetailsBaseProps {
  containerRef: RefObject<HTMLDivElement>;
  antibioticTarget?: L2Target[] | L2Target;
}

export interface SiteDetailsFullProps extends SiteDetailsBaseProps {
  title: string;
  explanation: ReactNode;
  target: L2Targets;
  id: string;
}
