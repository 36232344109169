import { format, isValid } from 'date-fns';
import { isNil } from 'lodash';
import { L2Targets } from './assays';
import { ProjectStatus } from './types';
import { TRACES_VAL } from './utils';

// TODO: This should be undefined, instead of 1900, but it seems to break something currently
export const DEFAULT_START_INTERVAL: Readonly<Date> = Object.freeze(new Date('1900'));
export const DEFAULT_END_INTERVAL: Readonly<Date> = Object.freeze(new Date('9999'));

export function friendlyDate(
  input: string | Date,
  mondayInfo: 'none' | 'prefixMon' | 'replaceMon',
  alwaysIncludeYear = false,
) {
  const date: Date = ensureUtcMidnight(input);
  const shortMonth = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const dayOfMonth = date.getUTCDate();
  const yearPostfix =
    alwaysIncludeYear || (dayOfMonth === 1 && shortMonth === 'Jan') ? ` ${date.getUTCFullYear()}` : '';
  const isMonday = mondayInfo !== 'none' && date.getUTCDate() === 1;
  if (mondayInfo === 'replaceMon' && isMonday) {
    return `Mon`;
  }
  const mondayPrefix = isMonday && mondayInfo === 'prefixMon' ? 'Mon. ' : '';
  return `${mondayPrefix}${dayOfMonth}. ${shortMonth}${yearPostfix}`;
}

export function friendlyMonth(input: string | Date, yearInfo: 'postfixAlways' | 'postfixJan' | 'replaceJan') {
  const date: Date = ensureUtcMidnight(input);
  const shortMonth = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const dayOfMonth = date.getUTCDate();
  const year =
    yearInfo === 'postfixAlways' || (dayOfMonth === 1 && shortMonth === 'Jan') ? `${date.getUTCFullYear()}` : '';

  return yearInfo === 'replaceJan' && year
    ? year
    : normalizeSpaces(`${date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' })} ${year}`);
}

export function friendlyAbundance(value: number | undefined | null) {
  if (!value || value === TRACES_VAL) {
    return '-';
  }
  return value.toFixed(value > 1 ? 0 : 6);
}

export function ensureDate(input: string | Date): Date {
  return new Date(input);
}

/**
 * Crops an arbitrary date to a UTC midnight
 */
export function ensureUtcMidnight(date: Date | string): Date {
  const dateObj = ensureDate(date);
  if (!isValid(dateObj)) {
    return dateObj;
  }
  return new Date(Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate()));
}

export function ensureUtcMonth(date: Date | string): Date {
  const dateObj = ensureDate(date);
  if (!isValid(dateObj)) {
    return dateObj;
  }
  return new Date(Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth()));
}

export function utcStartOfNextMonth(date: Date | string): Date {
  const dateObj = ensureUtcMonth(date);
  if (!isValid(dateObj)) {
    return dateObj;
  }
  return dateObj.getUTCMonth() == 11
    ? new Date(Date.UTC(dateObj.getUTCFullYear() + 1, 0))
    : new Date(Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth() + 1));
}

export function ensureValidUtcMidnightOrDefault(input: string | Date | null | undefined, defaultDate: Date): Date {
  const inputDate = input ? ensureUtcMidnight(input) : undefined;
  return inputDate && isValid(inputDate) ? inputDate : defaultDate;
}

export function getProjectStatusInfo(status: ProjectStatus): string {
  switch (status) {
    case ProjectStatus.DRAFT:
      return 'New project to be reviewed. All drafts should eventually be moved to another status or deleted. Excluded from global statistics for now.';
    case ProjectStatus.APPROVED:
      return 'Reviewed and approved project with representable data to be included in global statistics. All representable samples should be included in exactly one approved project. Approving a project makes the sample sheet visible to customers who have access to the project. Counted towards global statistics.';
    case ProjectStatus.APPROVED_WITH_ISSUES:
      return 'Approved, but eg with suspected master mix or other issues. Select this only if the project is not re-analysed. This also makes the sample sheet visible to customers who have access to the project. Counted towards total analysed samples, but not used for global statistics.';
    case ProjectStatus.FAILED:
      return 'Failed project, where analysis or handling of some samples had issues. All samples should be re-analysed and included in another, approved project. Excluded from global statistics.';
    case ProjectStatus.POOLED:
      return 'Pooled project that has been combined from other, approved projects by automatically with the Pooled project tool. Excluded from global statistics to avoid double counting.';
    case ProjectStatus.POOLED_MANUAL:
      return 'An old, manually pooled project (by creating another lab sheet). Excluded from global statistics to avoid double counting.';
    case ProjectStatus.DUPLICATE:
      return 'Similar to pooled project, this project includes samples that are present in other approved project. Excluded from global statistics to avoid double counting';
    case ProjectStatus.RESEARCH:
      return 'Research project that includes some weirdness, only references samples, or a mix of duplicatory and unique samples. Note that you can still mark project as approved if it has some reference samples, as long as those samples are clearly separate from the normal samples based on sample type and sub type. Excluded from global statistics.';
    case ProjectStatus.LEGACY:
      return 'Very old legacy project, without proper results (only html heatmap available). A couple of old projects have legacy status, but there should be no need to set the status to LEGACY for any more projects. Excluded from global statistics.';
  }
}

export function friendlyShortMonth<T>(value: T) {
  const dateValue =
    value instanceof Date ? value : typeof value === 'string' ? new Date(value) : new Date(Number(value));
  return format(dateValue, 'MMM');
}

export function capitalizeWords(str: string): string {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeOnlyWords(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstLetterOnly(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function sentanceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function normalizeSpaces(str: string): string {
  return str.replace(/\s+/g, ' ');
}
export function truncateLongString(string: string) {
  return string.length > 10 ? `${string.slice(0, 10)}...` : string;
}

export function friendlyCopyNumber(value: number | null | undefined) {
  if (isNil(value)) {
    return '-';
  }

  // Show decimals when the number is low enough. So that the decimal can fit there and there
  // won't be awkward situations, where we show 1M and 1M, instead of 1M and 1.2M
  const millions = value >= 1_000_000 && value / 1_000_000;
  const thousands = value >= 1_000 && value / 1_000;
  const dividedValue = millions || thousands;
  if (!dividedValue) {
    return value.toFixed(0);
  }
  const showDecimals = (millions && value < 5_000_000) || (thousands && value < 5_000);
  const withDecimal = showDecimals ? dividedValue.toFixed(1) : dividedValue.toFixed(0);
  const formatted =
    showDecimals && withDecimal[withDecimal.length - 1] === '0' ? withDecimal.slice(0, -2) : withDecimal;

  return millions ? `${formatted}M` : thousands ? `${formatted}k` : value.toFixed(0);
}

export function friendlyFoldChange(d: number | null | undefined, fixedDecimals: number = 1) {
  if (isNil(d)) {
    return '-';
  }
  const plusSign = d > 0 ? '+' : '';
  return `${plusSign}${d.toFixed(fixedDecimals)}`;
}

export function friendlyPercentage(value: number | null | undefined) {
  if (isNil(value)) {
    return '-';
  }
  if (value > 999) {
    return '>999%';
  }
  return `${value > 0 ? '+' : ''}${value.toFixed(0)}%`;
}

export function friendlyL2Target(l2Target: L2Targets): string {
  switch (l2Target) {
    case L2Targets.BETA_LACTAM:
      return 'Beta-Lactam';
    case L2Targets.MGE:
      return 'MGE';
    case L2Targets.INTEGRONS:
      return 'Integrons';
    case L2Targets.VANCOMYCIN:
      return 'Vancomycin';
    case L2Targets.MDR:
      return 'MDR';
    case L2Targets.TRIMETHOPRIM:
      return 'Trimethoprim';
    case L2Targets.PHENICOL:
      return 'Phenicol';
    case L2Targets.QUINOLONE:
      return 'Quinolone';
    case L2Targets.SULFONAMIDE:
      return 'Sulfonamide';
    case L2Targets.TETRACYCLINE:
      return 'Tetracycline';
    case L2Targets.AMINOGLYCOSIDE:
      return 'Aminoglycoside';
    case L2Targets.MLSB:
      return 'MLSB';
    case L2Targets.OTHER_RESISTANCE_MARKER:
      return 'Other Resistance Marker';
    case L2Targets.PATHOGEN_MARKER:
      return 'Pathogen Marker';
    case L2Targets.OTHER_TAXONOMIC_MARKER:
      return 'Taxonomic Marker';
    case L2Targets.OTHER_MICROBIAL_MARKER:
      return 'Other Microbial Marker';
    case L2Targets.SIXTEENS_RRNA:
      return '16S rRNA';
  }
}
