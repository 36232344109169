import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { BarDatum, getBarDatumKeys } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useExperimentalMetric } from '@resistapp/client/hooks/use-experimental-metric';
import { useNormalisationMode } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { GeneGrouping, L2Target, L2Targets } from '@resistapp/common/assays';
import { friendlyL2Target } from '@resistapp/common/friendly';
import { isConsideredAbsolute } from '@resistapp/common/normalisation-mode';
import { AxisBottom, AxisLeft, AxisScale, TicksRendererProps } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { GridLines } from '@visx/grid/lib/types';
import { Group } from '@visx/group';
import { ScaleInput, scaleLog } from '@visx/scale';
import { Text } from '@visx/text';
import { ScaleLinear, ScaleOrdinal } from 'd3-scale';
import { Dictionary, sortBy } from 'lodash';
import { getGroupTextColor } from '../../shared/palettes';
import { theme } from '../../shared/theme';
import { chartLayoutValues } from './utils';

const Y_AXIS_HEATMAP_LABEL_LEFT_OFFSET = 75; // Labels position horizontally - left to right
const Y_AXIS_HEATMAP_LABEL_ROTATION = -45; // Antibiotic group label rotation angle
const Y_AXIS_HEATMAP_LEFT_OFFSET = -20; // Position of both labels and ticks horizontally - left to right
const Y_AXIS_HEATMAP_TICK_LEFT_OFFSET = 10; // Gap between the tick and the label
const Y_AXIS_HEATMAP_TICK_LENGTH = 5; // Length of the tick

interface YAxisProps {
  yMax: number;
  xMax: number;
  yScale: ScaleLinear<number, number>;
  extremeYValue?: number;
  numTicks?: number;
}

interface RelativeAbundanceAxisProps extends YAxisProps {
  extremeYValue: number;
}

export function BoxPlotRelativeAbundanceAxisY(props: RelativeAbundanceAxisProps) {
  const { xMax, yMax, extremeYValue, yScale, numTicks } = props;
  const normalisationMode = useNormalisationMode();
  const logScale = scaleLog<number>({
    range: [yMax, 0],
    round: true,
    domain: isConsideredAbsolute(normalisationMode)
      ? [1, 10 ** Math.ceil(extremeYValue)]
      : [10 ** Math.floor(extremeYValue), 1],
  });

  const yTicks = numTicks || Math.ceil(Math.abs(extremeYValue));

  return (
    <>
      <GridRows
        scale={logScale}
        stroke={oldTheme.faintGray}
        left={chartLayoutValues.yAxisLeftMargin}
        width={xMax - chartLayoutValues.yAxisLeftMargin * 2}
        height={yMax}
      >
        {AllButFirstGridLine}
      </GridRows>
      <AxisLeft
        scale={yScale}
        numTicks={yTicks}
        tickLength={4}
        stroke="transparent"
        tickStroke={theme.colors.neutral400}
        left={-chartLayoutValues.yAxisMarginLeft}
        tickLabelProps={tickValue => ({
          x: -20,
          y: yScale(tickValue) + 4, // TODO investigate
          textAnchor: 'middle',
        })}
        tickFormat={(t, _i) => {
          return String(t);
        }}
        labelOffset={30}
      />
    </>
  );
}

interface GeneAxisProps {
  singleGeneHeatBoxHeight: number;
  example: BarDatum | undefined;
  sortIdxByGene: Dictionary<number>;
  grouping: GeneGrouping;
  colorScale: ScaleOrdinal<string, string> | undefined;
}

export function HeatMapGenesAxisY({
  example,
  colorScale,
  grouping,
  sortIdxByGene,
  singleGeneHeatBoxHeight,
}: GeneAxisProps) {
  const { allGeneGroups, getGroup } = useAssayContext();
  const genes = example ? sortBy(getBarDatumKeys(example), k => sortIdxByGene[k]) : [];
  let mutatingPrevGroupTop = 0;
  return (
    <Group style={{ zIndex: theme.zIndexes.heatmapYZ }} left={Y_AXIS_HEATMAP_LEFT_OFFSET}>
      {genes.map((gene, i) => {
        const group = getGroup(gene, grouping) || '';
        const prevGroup = genes[i - 1] && getGroup(genes[i - 1], grouping);
        const fullLabel =
          i === 0 || prevGroup !== group ? (group in L2Targets ? friendlyL2Target(group as L2Targets) : group) : '';
        const shortLabel = fullLabel.length > 12 ? `${fullLabel.substring(0, 10)}.`.replace(/ \.$/, '…') : fullLabel;
        const textWidth = getTextWidth(fullLabel as L2Target);
        const color = getGroupTextColor(group, grouping, allGeneGroups);

        const topOffset = 15;
        const top = i * singleGeneHeatBoxHeight - topOffset;
        const fullBgColor = colorScale ? colorScale(group) : '#FFFFFF';
        const labelsOverlapThreshold = 20;
        const backgroundColor =
          i > 0 && top - mutatingPrevGroupTop <= labelsOverlapThreshold
            ? transformToTransparent(fullBgColor)
            : fullBgColor;
        if (fullLabel) {
          mutatingPrevGroupTop = top;
        }

        const labelX = chartLayoutValues.plotLeftOffset - Y_AXIS_HEATMAP_LABEL_LEFT_OFFSET;
        const labelY = i * singleGeneHeatBoxHeight;

        return (
          <Group key={`gene-tick-${i}`}>
            {shortLabel && (
              <>
                <Group
                  transform={`translate(${labelX}, ${labelY}) rotate(${Y_AXIS_HEATMAP_LABEL_ROTATION})`}
                  className="download_heatmap-genes-axis-text-rotation"
                >
                  <rect
                    x={-textWidth - 4}
                    y={-10}
                    width={textWidth + 8}
                    height={20}
                    fill={backgroundColor}
                    rx={8}
                    ry={8}
                  />
                  <Text x={-textWidth / 2} y={4} textAnchor="middle" fill={color} fontSize={oldTheme.fontSize.xs}>
                    {shortLabel}
                  </Text>
                </Group>
                <line
                  shapeRendering="crispEdges" // This makes the ticks look sharper and thinner.
                  x1={Y_AXIS_HEATMAP_TICK_LEFT_OFFSET}
                  y1={labelY}
                  x2={Y_AXIS_HEATMAP_TICK_LEFT_OFFSET + Y_AXIS_HEATMAP_TICK_LENGTH}
                  y2={labelY}
                  stroke={theme.colors.neutral400}
                  strokeWidth={1}
                />
              </>
            )}
          </Group>
        );
      })}
    </Group>
  );
}

// Hardcoded hack to get text widths for gene labels
// because just relying on string length is not adequate
// and I don't know how to get the actual rendered widths
function getTextWidth(target: string) {
  switch (target) {
    case 'MGE':
    case 'MDR':
      return 36;
    case 'MLSB':
      return 44;
    case 'Other':
      return 42;
    case 'Class A':
    case 'Class B':
    case 'Class C':
    case 'Class D':
      return 52;
    case 'Phenicol':
      return 60;
    case 'Integrons':
      return 65;
    case 'Tetracycline':
      return 78;
    case 'Beta-Lactam':
    case 'Trimethoprim':
      return 85;
    case 'Quinolone':
    case 'Insertion sequence': // abbrevieated
      return 68;
    case 'Taxonomic':
    case 'Plasmid replication': // abbrevieated
    case 'Plasmid incompatibility': // abbrevieated
      return 72;
    case 'Vancomycin':
    case 'Sulfonamide':
    case 'Aminoglycoside': // abbrevieated
      return 80;
    case 'Transposase':
      return 82;

    default:
      return 65;
  }
}

const labelStyle = {
  fontFamily: oldTheme.fontFamily,
  textAnchor: 'middle' as const,
};
const xLabelStyle = {
  ...labelStyle,
  fontSize: oldTheme.fontSize.s,
};
const yLabelStyle = {
  ...labelStyle,
  fontSize: oldTheme.fontSize.xs,
};

export function BarPlotDetectedGenesAxisY(props: YAxisProps) {
  const { xMax, yMax, yScale } = props;

  return (
    <>
      <GridRows
        scale={yScale}
        stroke={oldTheme.faintGray}
        left={chartLayoutValues.yAxisLeftMargin}
        width={xMax - chartLayoutValues.yAxisLeftMargin * 2}
        height={yMax}
      >
        {AllButFirstGridLine}
      </GridRows>
      <AxisLeft
        scale={yScale}
        numTicks={19 / 2}
        tickLength={4}
        tickFormat={s => (Number.isInteger(s) ? Number(s).toFixed(0) : '')}
        stroke="transparent"
        tickStroke={theme.colors.neutral400}
        left={-chartLayoutValues.yAxisMarginLeft}
        tickLabelProps={tickValue => ({
          y: yScale(tickValue) + 4,
          x: -20,
          ...xLabelStyle,
        })}
        labelOffset={30}
      />
    </>
  );
}

interface XAxisProps {
  xScale: AxisScale;
  againstTime: boolean;
  height: number;
  numTicks: number;
  getLabel?: (x: ScaleInput<AxisScale>) => string;
  showOnlyTicks: boolean;
}

export function OrdinalOrTimeAxisX(props: XAxisProps) {
  const experimental = useExperimentalMetric();
  if (experimental) {
    return null;
  }
  return (
    <AxisBottom
      scale={props.xScale}
      top={chartLayoutValues.xAxisHeight - chartLayoutValues.xAxisTopMargin}
      numTicks={props.numTicks}
      tickFormat={props.showOnlyTicks ? () => '' : props.getLabel}
      stroke="transparent"
      ticksComponent={p => <DoubleTicks {...p} againstTime={props.againstTime} />}
    />
  );
}

function DoubleTicks<Scale extends AxisScale>(props: TicksRendererProps<Scale> & { againstTime: boolean }) {
  return (
    <g>
      {props.ticks.map((tick, i) => (
        <svg key={i}>
          <DoubleTick key={i} x={tick.to.x} y={tick.to.y} formattedValue={tick.formattedValue} />
        </svg>
      ))}
    </g>
  );
}

function DoubleTick(props: { x: number; y: number; formattedValue: string | undefined }) {
  const { x, y, formattedValue } = props;
  const stroke = theme.colors.neutral400;
  const tickLength = 8;
  const upperTickY = 0;
  const lowerTickY = chartLayoutValues.xAxisHeight - chartLayoutValues.xAxisTopMargin;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <line y1={upperTickY} y2={upperTickY - tickLength} stroke={stroke} />
      <line y1={lowerTickY} y2={lowerTickY + tickLength} stroke={stroke} />
      <text y={chartLayoutValues.xAxisHeight / 2} fill={oldTheme.dark} {...yLabelStyle} textAnchor="middle">
        {formattedValue}
      </text>
    </g>
  );
}

interface StickyXAxisProps extends XAxisProps {
  width: number;
  showOnlyTicks: boolean;
}

export function BarBoxOrdinalOrTimeAxisX(props: StickyXAxisProps) {
  return (
    <Group
      top={props.height - chartLayoutValues.xAxisHeight}
      left={chartLayoutValues.plotLeftOffset - chartLayoutValues.xAxisLeftMargin}
      style={{ overflow: 'visible' }}
      width={props.width - chartLayoutValues.plotLeftOffset}
      className="download_x-axis"
    >
      <OrdinalOrTimeAxisX {...props} />
    </Group>
  );
}

/**
 * Helper function to render grid lines, omitting the first line.
 * @param {GridLines} lines - The grid lines data.
 * @param {string} strokeColor - Color for the grid lines.
 * @param {number} strokeWidth - Width of the stroke for grid lines.
 */
function AllButFirstGridLine({ lines }: { lines: GridLines }) {
  return (
    <>
      {lines.slice(1).map((line, index) => (
        <line
          key={index}
          x1={line.from.x}
          y1={line.from.y}
          x2={line.to.x}
          y2={line.to.y}
          stroke={oldTheme.faintGray}
          strokeWidth="1"
        />
      ))}
    </>
  );
}

function transformToTransparent(color: string) {
  return `${color}C0`;
}
