import {
  LoginRequest,
  PatchOrPostOrganizationRequest,
  PoolProjectRequest,
  PooledProjectCheckResponse,
  PostProjectRequest,
  ProjectImageRequest,
  SignupUserRequest,
  VerifyUserRequest,
  WorldmapResponse,
} from '@resistapp/common/api-types';
import {
  CountryByAlpha3,
  CsvRows,
  FullProject,
  FullSamplesByUID,
  Organisation,
  Project,
  RawUser,
  User,
} from '@resistapp/common/types';
import { UseMutationOptions, UseMutationResult, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { csvFormat } from 'd3-dsv';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParamsContext } from '../contexts/search-params-context';
import { ApiError } from '../utils/error';
import { API_URL, getAuthHeaders, parseFetchResponseOld, resistomapApiClient } from '../utils/fetch';
enum CallPhase {
  INITIAL = 0,
  LOADING = 1,
  SUCCESS = 2,
  ERROR = 3,
}

export interface ApiResponse<T> {
  data?: T | ApiError;
  status?: number;
  statusText?: string;
}

export interface CallInfo<T> {
  loading: boolean;
  error?: ApiError;
  data?: T;
}

interface DelayedCallInfo<R, T> extends CallInfo<T> {
  send: (body?: R) => Promise<ApiResponse<T>>;
}

function getCallInfo<T>(response: ApiResponse<T> | undefined, callStatus: CallPhase) {
  const status = response?.status;
  const loading = callStatus === CallPhase.LOADING;
  if (callStatus === CallPhase.INITIAL) {
    return { loading };
  } else if (callStatus === CallPhase.LOADING) {
    return { loading };
  } else if (callStatus === CallPhase.SUCCESS && !!status && status >= 200 && status <= 204) {
    return { loading, data: response.data as T };
  } else {
    const errorMaybe = response?.data as ApiError | undefined;
    const message = errorMaybe?.message || 'Oops, something went wrong';
    const error = new Error(message);
    (error as ApiError).statusCode = errorMaybe?.statusCode;
    error.name = errorMaybe?.name || error.name;
    return {
      loading,
      error,
    };
  }
}

export async function fetchOrThrow<RequestBody, Response>(
  method: string,
  path: string,
  bodyObj?: RequestBody,
): Promise<ApiResponse<Response>> {
  const url = `${API_URL}${path}`;
  const body: undefined | string | FormData =
    bodyObj && (bodyObj instanceof FormData ? (bodyObj as FormData) : JSON.stringify(bodyObj));
  const headers = getAuthHeaders(bodyObj instanceof FormData ? undefined : 'application/json');
  const response = await fetch(url, {
    method,
    headers,
    body,
    credentials: 'include',
  });

  return await parseFetchResponseOld(response);
}

function getFetchHandler<T>(
  promise: Promise<ApiResponse<T>> | undefined,
  setCallStatus: (value: React.SetStateAction<CallPhase>) => void,
  setResponse: (value: React.SetStateAction<ApiResponse<T> | undefined>) => void,
  cancelObj: { cancel: boolean },
) {
  return async () => {
    if (promise) {
      setCallStatus(CallPhase.LOADING);
      try {
        const result = await promise;
        if (!cancelObj.cancel) {
          setResponse(result);
          setCallStatus(CallPhase.SUCCESS);
        }
      } catch (error) {
        if (error instanceof Error) {
          if (!cancelObj.cancel) {
            setResponse({ data: new Error(error.message) });
            setCallStatus(CallPhase.ERROR);
          }
        } else {
          setResponse({ data: new Error('unknown error occured') });
          setCallStatus(CallPhase.ERROR);
        }
      }
    }
  };
}

function useApiLater<R, T>(method: string, path: string): DelayedCallInfo<R, T> {
  const [callStatus, setCallStatus] = useState<CallPhase>(CallPhase.INITIAL);
  const [promise, setPromise] = useState<Promise<ApiResponse<T>> | undefined>(undefined);
  const [response, setResponse] = useState<ApiResponse<T> | undefined>(undefined);
  useEffect(() => {
    const cancelObj = { cancel: false };
    const handle = getFetchHandler(promise, setCallStatus, setResponse, cancelObj);
    void handle();
    return () => {
      cancelObj.cancel = true;
    };
  }, [promise]);

  const send = useCallback(
    async (body?: R) => {
      const p = fetchOrThrow<R, T>(method, path, body);
      setPromise(p);
      return await p;
    },
    [method, path],
  );

  return {
    ...getCallInfo(response, callStatus),
    send,
  };
}

function useGetOld<T>(path: string): CallInfo<T> {
  const [callStatus, setCallStatus] = useState<CallPhase>(CallPhase.INITIAL);
  const [response, setResponse] = useState<ApiResponse<T> | undefined>(undefined);
  useEffect(() => {
    const cancelObj = { cancel: false };
    const promise = fetchOrThrow<undefined, T>('GET', path);
    const handle = getFetchHandler(promise, setCallStatus, setResponse, cancelObj);
    void handle();
    return () => {
      cancelObj.cancel = true;
    };
  }, [path]);
  return getCallInfo(response, callStatus);
}

export function useGet<D>(endpoint: string, options?: Omit<UseQueryOptions<D>, 'queryKey'>) {
  const headers = getAuthHeaders('application/json');
  return useQuery<D>({
    queryKey: [endpoint],
    queryFn: async () => {
      const response = await resistomapApiClient.get<D>(endpoint, { headers });
      return response.data;
    },
    ...(options || {}),
  });
}

function usePost<Request, Response>(
  endpoint: string,
  options?: UseMutationOptions<Response, Error, Request>,
): UseMutationResult<Response, Error, Request> {
  const headers = getAuthHeaders('application/json');
  return useMutation<Response, Error, Request>({
    mutationFn: async (body: Request) => {
      const response = await resistomapApiClient.post<Response>(endpoint, body, { headers });
      return response.data;
    },
    ...(options || {}),
  });
}

export function useDynamicPost<Request, Response, EndPointArgs>(
  getEndpoint: (args: EndPointArgs) => string,
  options?: Omit<UseMutationOptions<Response, Error, { body: Request; args: EndPointArgs }>, 'mutationFn'>,
): UseMutationResult<Response, Error, { body: Request; args: EndPointArgs }> {
  const headers = getAuthHeaders('application/json');
  return useMutation<Response, Error, { body: Request; args: EndPointArgs }>({
    mutationFn: async ({ body, args }) => {
      const endpoint = getEndpoint(args);
      const response = await resistomapApiClient.post<Response>(endpoint, body, { headers });
      return response.data;
    },
    ...(options || {}),
  });
}

function usePatch<Request, Response>(
  endpoint: string,
  options?: Omit<UseMutationOptions<Response, Error, Request>, 'mutationFn'>,
): UseMutationResult<Response, Error, Request> {
  const headers = getAuthHeaders('application/json');
  return useMutation<Response, Error, Request>({
    mutationFn: async (body: Request) => {
      const response = await resistomapApiClient.patch<Response>(endpoint, body, { headers });
      return response.data;
    },
    ...(options || {}),
  });
}

function useDelete<D>(endpoint: string) {
  const headers = getAuthHeaders('application/json');
  return useMutation<D>({
    mutationFn: async () => {
      const response = await resistomapApiClient.delete<D>(endpoint, { headers });
      return response.data;
    },
  });
}

function usePostOld<R, T>(endpoint: string) {
  return useApiLater<R, T>('POST', endpoint);
}

export function useProjects() {
  return useGetOld<Project[]>('/projects');
}

export function useOrganizations() {
  return useGet<Organisation[]>('/organizations', { refetchOnWindowFocus: false });
}

export function usePatchOrganization() {
  return usePatch<PatchOrPostOrganizationRequest, Organisation>('/organization');
}

export function useDeleteOrganization(id: number) {
  return useDelete<null>(`/organization/${id}`);
}

export function useCountries() {
  return useGetOld<CountryByAlpha3>('/countries');
}

export function useWorldmap() {
  const { searchParams } = useSearchParamsContext();
  const all = searchParams.get('all');
  const type = searchParams.get('type');
  return useGetOld<WorldmapResponse>(`/worldmap?type=${type}${all ? '&all' : ''}`);
}

export function useProject(id: number | string): ReturnType<typeof useQuery<FullProject>> {
  // Some old, incomplete samplings may lack results
  return useGet<FullProject>(`/project/${id}`, { refetchOnWindowFocus: false });
}

export function useSignup() {
  const create = usePost<SignupUserRequest, boolean>(`/user/signup`);
  return { create };
}

export function useVerifyEmail() {
  const create = usePost<VerifyUserRequest, User>(`/user/verify`);
  return { create };
}

export function useProjectImage(id: number) {
  return usePostOld<ProjectImageRequest, Blob>(`/project/${id}/image`);
}

export function useDeleteProject(id: number | string) {
  return useApiLater<undefined, boolean>('DELETE', `/project/${id}`);
}

export function useDeleteAccess(samplingId: number | string, userId: number | string) {
  return useApiLater<undefined, boolean>('DELETE', `/project/${samplingId}/user/${userId}`);
}

export function useDeleteOrganizationAccess(organizationId: number | string, samplingId: number | string) {
  return useDelete<boolean>(`/organization/${organizationId}/project/${samplingId}`);
}

export function useDeleteOrganizationUser(organizationId: number, userId: number) {
  return useDelete<boolean>(`/organization/${organizationId}/user/${userId}`);
}

interface PostOrganizationUserArgs {
  organizationId: number;
  userId: number;
}
export function usePostOrganizationUser() {
  const poster = useDynamicPost<undefined, undefined, PostOrganizationUserArgs>(
    ({ organizationId, userId }: PostOrganizationUserArgs) => `/organization/${organizationId}/user/${userId}`,
  );
  return async (organizationId: number, userId: number) => {
    await poster.mutateAsync({ body: undefined, args: { organizationId, userId } });
  };
}
export function useUsers() {
  return useApiLater<undefined, User[]>('GET', `/users`);
}

export function useDeleteUser(id: number | string) {
  return useApiLater<undefined, boolean>('DELETE', `/user/${id}`);
}

export function useDeleteUserProject(userId: number | string, samplingId: number | string) {
  return useApiLater<undefined, boolean>('DELETE', `/user/${userId}/project/${samplingId}`);
}
export function useDeleteOrganizationProject(organizationId: number | string, samplingId: number | string) {
  return useApiLater<undefined, boolean>('DELETE', `/organization/${organizationId}/project/${samplingId}`);
}

export async function fetchCorrelations(focusedByUID: FullSamplesByUID): Promise<string> {
  const response = await fetchOrThrow<FullSamplesByUID, CsvRows>('POST', '/correlations', focusedByUID);
  if (response.status === 200 && response.data) {
    return csvFormat(response.data as CsvRows);
  } else {
    return `${(response.data as ApiError).message || response.statusText}`;
  }
}

export function useGetUser() {
  return useApiLater<undefined, User>('GET', '/user');
}

export function usePostLogin(onSuccess?: (data: User) => void, onError?: (error: Error) => void) {
  const poster = useDynamicPost<LoginRequest, User, undefined>(() => `/login`, { onSuccess, onError });
  return async (email: string, password: string) => {
    await poster.mutateAsync({ body: { email, password }, args: undefined });
  };
}

export function usePostProject() {
  const { loading, error, data, send: sendData } = useApiLater<FormData, FullProject>('POST', `/project`);
  const send = (body: PostProjectRequest) => {
    const formData = new FormData();
    formData.append('name', body.name);
    formData.append('sheetLink', body.sheetLink);
    void sendData(formData);
  };
  return { loading, error, data, send };
}

export function usePatchProject(id: number) {
  const { loading, error, data, send: sendData } = useApiLater<FormData, FullProject>('PATCH', `/project/${id}`);
  const send = (formData: FormData) => {
    formData.append('id', `${id}`);
    void sendData(formData);
  };
  return { loading, error, data, send };
}

export function usePostPooledProject() {
  const check = usePost<PoolProjectRequest, PooledProjectCheckResponse>(`/pooled-project/check`);
  const create = usePost<PoolProjectRequest, FullProject>(`/pooled-project/create`);
  return {
    check,
    create,
  };
}

export function usePatchProjectImages(id: number) {
  const { loading, error, data, send: sendData } = useApiLater<FormData, true>('PATCH', `/project/${id}/filenames`);
  const send = (files: File[], deleteFilename?: string) => {
    const formData = new FormData();
    files.forEach((file, idx) => {
      formData.append(`file${idx}`, file);
    });
    if (deleteFilename) {
      formData.append(`deleteFilename`, deleteFilename);
    }
    void sendData(formData);
  };
  return { loading, error, data, send };
}

export function usePatchProjectStatus(id: number, onSuccess?: (data: true) => void) {
  return usePatch<string, true>(`/project/${id}/status`, { onSuccess });
}

export function usePatchProjectName(id: number, onSuccess?: (data: true) => void) {
  return usePatch<string, true>(`/project/${id}/name`, { onSuccess });
}

export function usePostUser() {
  const { loading, error, data, send: sendData } = usePostOld<RawUser, User>(`/user`);
  const send = (body: RawUser) => {
    void sendData(body);
  };
  return { loading, error, data, send };
}

export function usePatchProjectLevels(id: number) {
  return usePatch<Record<string, number[]>, true>(`/project/${id}/zoomable-levels`);
}
