import { useProject } from '@resistapp/client/hooks/api';
import { PathParams, usePathParam } from '@resistapp/client/hooks/use-path-params';
import { FullProject, NormalisationMode } from '@resistapp/common/types';
import { flattenSamplesByUID } from '@resistapp/common/utils';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { QueryFilters, useQueryFilters } from '../hooks/use-query-filters/use-query-filters';
import { ApplicationErrors } from '../utils/error';
import { determineAvailableNormalisationModes } from './use-overview-context/overview-context-utils';

export interface SampleData {
  data: FullProject | undefined;
  queryFilters: QueryFilters;
  availableNormalisationModes: NormalisationMode[];
  setAvailableNormalisationModesStable: React.Dispatch<React.SetStateAction<NormalisationMode[]>>;
  loading: boolean;
  error: Error | null;
  refetchData: () => void;
}

const SampleDataContext = createContext<SampleData | undefined>(undefined);

export function SampleDataProvider({ children }: { children: React.ReactNode }) {
  const projectId = usePathParam(PathParams.projectId);
  if (!projectId) {
    throw new Error(ApplicationErrors.projectID_is_missing);
  }
  const { data, isLoading, error, refetch } = useProject(projectId);

  const { queryFilters, focusSamplesByUID } = useQueryFilters(data);
  const focusedByUID = useMemo(() => {
    return !data || !queryFilters.hasFocus ? data?.samplesByUID : focusSamplesByUID(data.samplesByUID);
  }, [data, queryFilters.hasFocus, focusSamplesByUID]);
  if (data) {
    data.focusedByUID = focusedByUID;
  }

  const [availableNormalisationModes, setAvailableNormalisationModesStable] = useState(
    determineAvailableNormalisationModes(undefined),
  );
  useEffect(() => {
    setAvailableNormalisationModesStable(
      determineAvailableNormalisationModes(focusedByUID ? flattenSamplesByUID(focusedByUID) : undefined),
    );
  }, [focusedByUID]);

  const contextData = {
    loading: isLoading,
    error,
    data,
    availableNormalisationModes,
    setAvailableNormalisationModesStable,
    refetchData: refetch,
    queryFilters,
  };

  return <SampleDataContext.Provider value={contextData}>{children}</SampleDataContext.Provider>;
}

export function useSampleDataContext() {
  const context = useContext(SampleDataContext);

  if (!context) {
    throw new Error('useSampleDataContext must be used within a SampleDataProvider');
  }

  return context;
}
