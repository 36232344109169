import { type OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { FullSample } from '@resistapp/common/types';
import { chain } from 'lodash';
import { useMemo } from 'react';
import { useSampleDataContext } from '../../sample-data-context';

interface UseSingleSiteSelectedProps {
  supportedSamples: FullSample[] | undefined;
  mapData: OverviewDatum[] | undefined;
}

export function useSelectedSiteDatum({
  supportedSamples,
  mapData,
}: UseSingleSiteSelectedProps): OverviewDatum | undefined {
  const { queryFilters } = useSampleDataContext();

  const { selectedEnvironmentNamesOrdered } = queryFilters.filters;
  const selectedSiteDatum = useMemo(() => {
    const uniqSelectedLocations = chain(supportedSamples)
      .filter(s => selectedEnvironmentNamesOrdered.includes(s.environment.name))
      .map(s => `${s.lat},${s.lon}`)
      .uniq()
      .value();
    const singleSiteSelected =
      selectedEnvironmentNamesOrdered.length === 1 ||
      (selectedEnvironmentNamesOrdered.length === 2 && uniqSelectedLocations.length === 1);
    const selectedEnvNames = singleSiteSelected ? selectedEnvironmentNamesOrdered : [];
    return selectedEnvNames.length
      ? mapData?.filter(d =>
          selectedEnvNames.some(
            envName =>
              'originalEnvironmentNames' in d.environment && d.environment.originalEnvironmentNames?.includes(envName),
          ),
        )[0]
      : undefined;
  }, [mapData, supportedSamples, selectedEnvironmentNamesOrdered]);

  return selectedSiteDatum;
}
