import { zEnvironmentType } from '@resistapp/common/environment-types';
import { coerce } from 'semver';
import { z } from 'zod';
import { carbapenem, zL2Targets } from './assays';
import { zFeature } from './features';
import { HeatmapType, zMetricMode } from './types';

export const zLoginRequest = z.object({
  email: z.string(),
  password: z.string(),
});
export type LoginRequest = z.infer<typeof zLoginRequest>;

export const zPostProjectRequest = z.object({
  name: z.string(),
  sheetLink: z.string(),
});
export type PostProjectRequest = z.infer<typeof zPostProjectRequest>;

const zProjectImageRequest = z.object({
  filename: z.string(),
});
export type ProjectImageRequest = z.infer<typeof zProjectImageRequest>;

export const zAbundanceStats = z.object({
  min: z.number().optional(),
  firstQuartile: z.number().optional(),
  median: z.number().optional(),
  mean: z.number().optional(),
  thirdQuartile: z.number().optional(),
  max: z.number().optional(),
  outliers: z.array(z.number()).optional(),
});
export type AbundanceStats = z.infer<typeof zAbundanceStats>;

const zWorldmapResponse = z.record(
  // By grouping, country and group (+ all)
  z.string(),
  z.record(z.string(), z.record(z.string(), zAbundanceStats)),
);
export type WorldmapResponse = z.infer<typeof zWorldmapResponse>;

const zHeatmapResponse = z.object({
  [HeatmapType.ALL]: z.any(),
  [HeatmapType.DETECTED]: z.any(),
  [HeatmapType.QUANTIFIED]: z.any(),
  html: z.string(),
  csv: z.string(),
});
export type HeatmapResponse = z.infer<typeof zHeatmapResponse>;

export enum PoolingType {
  // Pooled project creation
  CITY = 'CITY',
  REGION = 'REGION',
  COUNTRY = 'COUNTRY',
  ENVIRONMENT_TYPE = 'ENVIRONMENT_TYPE',
  ENVIRONMENT = 'ENVIRONMENT', // Environments with the same name, type, subtype and lat lon are pooled together

  // On the fly sample pooling in the overview
  SITE_AND_ADMIN_LEVEL = 'SITE_AND_ADMIN_LEVEL',
  SITE = 'SITE',
}

export enum PoolingMode {
  SKIP_MISSING = 'SKIP_MISSING',
  THROW_MISSING = 'THROW_MISSING',
}

export const zPoolingType = z.nativeEnum(PoolingType);
export const zPoolingMode = z.nativeEnum(PoolingMode);
export const zPooling = z.union([
  z.object({
    mode: zPoolingMode,
    type: z.literal(PoolingType.SITE_AND_ADMIN_LEVEL),
    level: z.number(),
  }),
  z
    .object({
      mode: zPoolingMode,
      type: z.union([
        z.literal(PoolingType.CITY),
        z.literal(PoolingType.REGION),
        z.literal(PoolingType.COUNTRY),
        z.literal(PoolingType.ENVIRONMENT_TYPE),
        z.literal(PoolingType.ENVIRONMENT),
        z.literal(PoolingType.SITE),
      ]),
      level: z.number().optional(),
    })
    .omit({ level: true }),
]);
export type Pooling = z.infer<typeof zPooling>;

// NOTE: strive to keep this backwards compatible
// so that future code versions could (subject to data availability)
// re-calculate pooled projects after calculating new result versions
// for constituent projects
export const zPoolProjectRequest = z.object({
  name: z.string(),
  assaySetProjectId: z.number().optional(),
  projectIds: z.array(z.number()).optional(),
  minAssays: z.number().optional(),
  targets: z.array(z.union([zL2Targets, z.literal(carbapenem)])).optional(),
  environmentTypes: z.array(zEnvironmentType).optional(),
  countries: z.array(z.string()).optional(),
  includeRegexStr: z.string().optional(),
  excludeRegexStr: z.string().optional(),
  analyzeStartDate: z.string().optional(),
  analyzeEndDate: z.string().optional(),
  pooling: zPooling.optional(),
});
export type PoolProjectRequest = z.infer<typeof zPoolProjectRequest>;

export interface PooledProjectCheckResponse {
  numAssays: number;
  numIncludedSamples: number;
  numIncludedEnvs: number;
  numSamplesToBeCreated: number;
  numEnvsToBeCreated: number;
  numProjects: number;
  numCities: number;
  numRegions: number;
  numCountries: number;
}

export const zSignupUserRequest = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  organization: z.string().min(1, { message: 'Organization is required' }),
  position: z.string().min(1, { message: 'Position is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
  signupCode: z.string().min(1, { message: 'Organization code is required, please check your invitation link' }),
  verificationCode: z.string().optional(),
});
export type SignupUserRequest = z.infer<typeof zSignupUserRequest>;

export const zVerifyUserRequest = z.object({
  verificationCode: z.string(),
});
export type VerifyUserRequest = z.infer<typeof zVerifyUserRequest>;

const zOrganizationRequest = z.object({
  id: z.number().optional(),
  name: z.string().min(1, { message: 'Organization name is required' }),
  accesses: z.array(z.number()),
  features: z.array(zFeature),
  isDemo: z.boolean(),
  defaultMetric: zMetricMode.optional(),
});
export const zPostOrganizationRequest = zOrganizationRequest.extend({
  accesses: zOrganizationRequest.shape.accesses.default([]),
  features: zOrganizationRequest.shape.features.default([]),
  isDemo: zOrganizationRequest.shape.isDemo.default(false),
});
export type PostOrganizationRequest = z.infer<typeof zPostOrganizationRequest>;

const zPatchOrganizationRequest = zOrganizationRequest.partial().extend({
  id: z.number(),
});
export const zPatchOrPostOrganizationRequest = z.union([zPatchOrganizationRequest, zPostOrganizationRequest]);
export type PatchOrPostOrganizationRequest = z.infer<typeof zPatchOrPostOrganizationRequest>;

export const zPostVersionRequest = z.object({
  id: z.number().refine(n => Number.isInteger(n * 10) && n.toString().match(/^\d{4}\.\d+$/), {
    message: 'Version ID must be in format YYYY.N where N is an integer',
  }),
  assayVersion: z.string().refine(v => !!coerce(v), {
    message: 'Assay version must follow semver',
  }),
  name: z.string(),
  description: z.string(),
});
export type PostVersionRequest = z.infer<typeof zPostVersionRequest>;
