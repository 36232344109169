import { calculateMarkerBounds } from '@resistapp/client/components/map/markers/marker-utils';
import {
  activateColoredRegions,
  deactivateColoredRegions,
  getBoundingBoxForEnvironment,
} from '@resistapp/client/components/map/overview-map-utils';
import { MapSourceWithLevel, getMapSourceFromAdminLevel } from '@resistapp/client/utils/map-sources';
import { getAdminAreaKey } from '@resistapp/common/pool-samples';
import { AdminArea, getAllOriginalEnvironmentNames } from '@resistapp/common/types';
import { getCountriesFromSamples } from '@resistapp/common/utils';
import { Dictionary, chain, isNil } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MapboxMap } from 'react-map-gl';
import { useAssayContext } from './assay-context';
import { useSampleDataContext } from './sample-data-context';
import {
  GLOBAL_NAME,
  getAllSitesInAdminArea,
  getCountryAdminLevelIntersection,
  zoomAndCenterMap,
} from './use-overview-context/overview-context-utils';
import { useOverviewContext } from './use-overview-context/use-overview-context';

// If nextAdminArea is null, it means we selected site, if it was undefined, it means we un-selected project
export type ChangeZoomedAdminArea = (
  nextAdminArea: AdminArea | null | undefined,
  options?: { countryId?: string; previous?: boolean },
) => void;

export interface MapContextData {
  mapInstance: MapboxMap | null;
  setMapInstance: (mapInstance: MapboxMap | null) => void;
  setMapLoaded: (loaded: boolean) => void;
  activeMapSource: MapSourceWithLevel | undefined;
  changeZoomedAdminAreaStable: ChangeZoomedAdminArea;
}

const MapContext = createContext<MapContextData | undefined>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}

export function MapContextProvider({ children }: ProviderProps) {
  const {
    mapData,
    supportedSamples,
    shownAdminLevel,
    setShownAdminLevelStable,
    zoomedAdminAreaRef,
    previousAdminAreasLifo,
    levelsWithZoomableAreas,
    setZoomedAdminAreaStable,
    setPreviousAdminAreasLifoStable,
    metricMode,
    processMode,
    activeChartUnit,
    selectedCountry,
    setHoveredAreaOrSiteEnvIdStable,
    setSelectedCountryStable,
    selectedSiteDatum,
    selectedEnvironmentTypeGroup,
  } = useOverviewContext();
  const { getGroup, assaysLoaded, allAssays } = useAssayContext();
  const { queryFilters } = useSampleDataContext();

  // Map state
  const [mapInstance, setMapInstance] = useState<MapboxMap | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [activeMapSource, setActiveMapSource] = useState<MapSourceWithLevel | undefined>();

  // AUTOSELECT SITE WHEN ZOOMING INTO SINGLE SITE ADMIN AREA
  const areasActive = !!activeMapSource;
  const { toggleEnvironmentStable } = queryFilters;
  const zoomedAdminArea = zoomedAdminAreaRef.current;
  const selectedSiteName = selectedSiteDatum?.environment.name;
  useEffect(() => {
    if (zoomedAdminArea && !areasActive) {
      const sitesInAdminArea = getAllSitesInAdminArea(zoomedAdminArea, mapData);
      if (sitesInAdminArea?.length === 1) {
        const envName = sitesInAdminArea[0].environment.name;
        if (envName !== selectedSiteName) {
          toggleEnvironmentStable(envName, true);
        }
      }
    }
  }, [zoomedAdminArea, shownAdminLevel, mapData, areasActive, selectedSiteName]);

  // CALLBACK FOR CHANGING ZOOMED AREA ("onAreaClick")
  const levelsWithZoomableAreasRef = useRef(levelsWithZoomableAreas);
  const previousAdminAreasLifoRef = useRef(previousAdminAreasLifo);
  const mapInstanceRef = useRef(mapInstance);
  const mapLoadedRef = useRef(mapLoaded);
  levelsWithZoomableAreasRef.current = levelsWithZoomableAreas;
  previousAdminAreasLifoRef.current = previousAdminAreasLifo;
  mapInstanceRef.current = mapInstance;
  mapLoadedRef.current = mapLoaded;
  const changeZoomedAdminAreaStable = useCallback<ChangeZoomedAdminArea>((adminArea, options = {}) => {
    const { countryId, previous } = options;
    if (!mapInstanceRef.current || !mapLoadedRef.current) {
      return;
    }

    // If adminArea is magically undefined, it means site is being unselected
    if (adminArea === undefined && levelsWithZoomableAreasRef.current?.length) {
      setZoomedAdminAreaStable(null);
    } else if (
      previousAdminAreasLifoRef.current.length &&
      adminArea &&
      getAdminAreaKey(adminArea) ===
        getAdminAreaKey(previousAdminAreasLifoRef.current[previousAdminAreasLifoRef.current.length - 1])
    ) {
      setZoomedAdminAreaStable(adminArea);
      if (adminArea.level === 1) {
        setShownAdminLevelStable(2);
      } else {
        setShownAdminLevelStable(levelsWithZoomableAreasRef.current?.find(level => level > adminArea.level) || null);
      }
      if (previous) {
        if (
          previousAdminAreasLifoRef.current.length === 1 &&
          previousAdminAreasLifoRef.current[0].name === GLOBAL_NAME
        ) {
          setSelectedCountryStable(undefined);
        }
        setPreviousAdminAreasLifoStable(previousAdminAreasLifoRef.current.slice(0, -1));
      }
    } else if (!adminArea) {
      setZoomedAdminAreaStable(null);
      setShownAdminLevelStable(null);
    } else {
      if (countryId) {
        setSelectedCountryStable(countryId);
      }
      setZoomedAdminAreaStable(adminArea);
      setShownAdminLevelStable(levelsWithZoomableAreasRef.current?.find(level => level > adminArea.level) || null);
      if (zoomedAdminAreaRef.current && getAdminAreaKey(adminArea) !== getAdminAreaKey(zoomedAdminAreaRef.current)) {
        setPreviousAdminAreasLifoStable([...previousAdminAreasLifoRef.current, zoomedAdminAreaRef.current]);
      }
    }
  }, []);

  // PREPARE MAP SOURCES (COLORED REGIONS)
  const siteSelected = !!selectedSiteName; // DO NOT trigger this use effect on site selection change but only on site view <-> overview transitions
  const readyToActivatePrepare = !!mapData && !!mapLoaded && !!assaysLoaded && !!supportedSamples && !!mapInstance;
  useEffect(() => {
    // This data is mapbox specific data and we should only update it after the map and data is fully loaded
    if (!readyToActivatePrepare) {
      return;
    }

    const countries = getCountriesFromSamples(supportedSamples);
    const { country } =
      shownAdminLevel !== 2 && countries.length === 1
        ? getCountryAdminLevelIntersection(supportedSamples)
        : { country: selectedCountry };
    const mapSource = shownAdminLevel ? getMapSourceFromAdminLevel(shownAdminLevel, country) : undefined;

    if (!mapSource || siteSelected) {
      setActiveMapSource(undefined);
      deactivateColoredRegions(mapInstance);
    } else if (shownAdminLevel) {
      setActiveMapSource(mapSource);
      deactivateColoredRegions(mapInstance);
    }
  }, [readyToActivatePrepare, supportedSamples, mapInstance, shownAdminLevel, selectedCountry, siteSelected]);

  // ATIVATE COLORED REGIONS
  const { selectedTargets } = queryFilters.filters;
  useEffect(() => {
    if (!readyToActivatePrepare || !activeMapSource) {
      return;
    }

    // First deactivate existing colored regions
    // (this makes colored regions responsive to env type group change)
    deactivateColoredRegions(mapInstance);

    const boundingBoxes = chain(mapData)
      .map(dataLocal => {
        const environmentId = dataLocal.environment.id;
        return [environmentId, getBoundingBoxForEnvironment([dataLocal])];
      })
      .fromPairs()
      .value() as Dictionary<{ ne: [number, number]; sw: [number, number] }>;

    activateColoredRegions(
      mapInstance,
      activeMapSource,
      mapData,
      setHoveredAreaOrSiteEnvIdStable,
      boundingBoxes,
      changeZoomedAdminAreaStable,
      selectedTargets,
      metricMode,
      processMode,
      activeChartUnit,
      selectedEnvironmentTypeGroup,
      getGroup,
      allAssays,
    );
  }, [
    mapInstance,
    readyToActivatePrepare,
    selectedEnvironmentTypeGroup,
    mapData,
    activeMapSource,
    selectedTargets,
    metricMode,
    processMode,
    activeChartUnit,
    getGroup,
    allAssays,
  ]);

  // MAP ZOOMING
  useEffect(() => {
    if (!mapInstance || !mapLoaded || !mapData) {
      return;
    }

    // Priority 1: Site-specific zoom
    if (selectedSiteName) {
      const datum = mapData.find(d => {
        const siteEnvNames = getAllOriginalEnvironmentNames(d.environment, d.environmentAfter);
        return siteEnvNames.includes(selectedSiteName);
      });
      if (!isNil(datum?.inferredLon) && !isNil(datum.inferredLat)) {
        zoomAndCenterMap(mapInstance, { zoom: 13, center: [datum.inferredLon, datum.inferredLat] });
      }
      return;
    }

    // Priority 2: Admin area boundaries zoom
    if (zoomedAdminArea?.boundaries) {
      zoomAndCenterMap(mapInstance, { bounds: zoomedAdminArea.boundaries });
      return;
    }

    // Priority 3: General marker bounds zoom
    const bounds = calculateMarkerBounds(mapData);
    zoomAndCenterMap(mapInstance, { bounds });
  }, [mapInstance, mapLoaded, mapData, selectedSiteName, zoomedAdminArea]);

  const contextData: MapContextData = {
    mapInstance,
    setMapLoaded,
    setMapInstance,
    activeMapSource,
    changeZoomedAdminAreaStable,
  };

  return <MapContext.Provider value={contextData}>{children}</MapContext.Provider>;
}

export function useMapContext() {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMapContext must be used within a MapContextProvider');
  }
  return context;
}
