import { Flex, useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useMapContext } from '@resistapp/client/contexts/map-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useTutorialContext } from '@resistapp/client/contexts/tutorial-context';
import { useMetricAndLevel } from '@resistapp/client/contexts/use-overview-context/hooks/use-metric-and-level';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useTutorial } from '@resistapp/client/hooks/use-tutorial';
import { EnvironmentTypeGroup } from '@resistapp/common/comparable-env-groups';
import { friendlyEnvSubTypeOrType } from '@resistapp/common/environment-types';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { ArrowIcon } from '../icons/arrow-icon';
import { CloseIconSimple } from '../icons/close-icon';
import { theme } from '../shared/theme';
import { TutorialContainer } from '../tooltips/tutorial-container';
import { EnvironmentTitle, EnvironmentTitleSmall } from './environment-titles';
import { ResistanceBoxes } from './resistance-boxes';
import { ScopeSelectors } from './scope-selectors';
import { OptionBase, OptionForTargets } from './types';

export function OverviewFocusHeader() {
  const {
    selectedSiteDatum,
    zoomedMapData,
    previousAdminAreasLifo,
    metricMode,
    processMode,
    mapDataByLevel,
    levelsWithZoomableAreas,
  } = useOverviewContext();
  const { changeZoomedAdminAreaStable } = useMapContext();
  const { queryFilters } = useSampleDataContext();
  const { currentTutorialStep, tutorial, nextStep, prevStep, stopTutorial } = useTutorial('overview');
  const { setTutorialChanged } = useTutorialContext();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const siteCount = mapDataByLevel && mapDataByLevel['null'].length;
  const samplingPointsCount =
    mapDataByLevel && mapDataByLevel['null'].reduce((acc, d) => acc + (d.environmentAfter ? 2 : 1), 0);
  const topLevelMapData =
    mapDataByLevel && levelsWithZoomableAreas?.length ? mapDataByLevel[levelsWithZoomableAreas[0]][0] : undefined;

  // TOOD FIX THIS TO SHOW THE METRICS CORRECTLY

  const { toggleEnvironmentStable: toggleEnvironment } = queryFilters;
  const topLevelEnv = topLevelMapData?.environment;
  const selectedOrZoomedDatum = selectedSiteDatum || zoomedMapData;
  const selectedOrZoomedEnv = selectedOrZoomedDatum?.environment;

  const { metric: metricTopAdminLevel, level: levelTopAdminLevel } = useMetricAndLevel(topLevelMapData);
  const { metric: metricBefore, level: levelBefore } = useMetricAndLevel(selectedOrZoomedDatum, {
    processMode: ProcessMode.BEFORE,
  });
  const { metric: metricAfter, level: levelAfter } = useMetricAndLevel(selectedOrZoomedDatum, {
    processMode: ProcessMode.AFTER,
  });
  const { metric: primaryMetric, level: primaryLevel } = useMetricAndLevel(selectedOrZoomedDatum);

  const hasBeforeAbundances = !!selectedOrZoomedDatum?.beforeAbundances;
  const hasAfterAbundances = !!selectedOrZoomedDatum?.afterAbundances;

  const firstLevelLabel = metricMode === MetricMode.REDUCTION ? 'Change' : hasBeforeAbundances ? 'Raw' : undefined;
  const secondLevelLabel = metricMode !== MetricMode.REDUCTION && hasAfterAbundances ? 'Treated' : undefined;

  const previousAdminArea =
    previousAdminAreasLifo.length > 0 && previousAdminAreasLifo[previousAdminAreasLifo.length - 1];

  const handleAntibioticChange = (value: OptionForTargets | null) => {
    const correctValue =
      !value || value.value === 'All antibiotics' ? queryFilters.filters.selectedTargets : value.value;
    queryFilters.toggleGeneGroupStable(correctValue, true);
  };
  const handleEnvTypeGroupChange = (value: OptionBase | null) => {
    const correctValue = !value ? queryFilters.filters.selectedEnvironmentTypeGroup : value.value;
    queryFilters.setEnvironmentTypeGroupStable(correctValue as EnvironmentTypeGroup, false);
  };

  const siteInfo =
    `${siteCount ?? '-'} Sites` +
    (samplingPointsCount && siteCount && samplingPointsCount > siteCount
      ? ` and ${samplingPointsCount} Sampling Points`
      : '');

  const TopLevelResistanceBoxes = (
    <ResistanceBoxes
      beforeOrChange={
        processMode === ProcessMode.BEFORE
          ? {
              metric: metricTopAdminLevel,
              level: levelTopAdminLevel,
              label: undefined,
            }
          : undefined
      }
      after={
        processMode === ProcessMode.AFTER
          ? {
              metric: metricTopAdminLevel,
              level: levelTopAdminLevel,
              label: undefined,
            }
          : undefined
      }
    />
  );

  // SPECS: How header and site process markers should behave render each level
  // https://docs.google.com/spreadsheets/d/1SDXnN0LI8_hNqwNgDlpKJtCFL_JiPif_5KxBQgW2e90/edit?gid=0#gid=0

  return (
    <Container className="test-overview-focus-header-container" flexDirection={{ lg: 'row' }}>
      {/* SMALL TOP LEVEL ENVIRONMENT BOX - WHEN SOMEHTING IS SELECTED */}
      {topLevelMapData && selectedOrZoomedEnv && selectedOrZoomedEnv.name !== topLevelEnv?.name && (
        <BorderContainerSmall height={{ md: '128px' }} display={{ base: 'none', lg: 'flex' }}>
          <EnvironmentTitleSmall environmentName={topLevelEnv?.name || ''} />
          {TopLevelResistanceBoxes}
        </BorderContainerSmall>
      )}
      {/* BIG TOP LEVEL ENVIRONMENT BOX - WHEN NO AREA OR SITE IS SELECTED */}
      {!selectedOrZoomedEnv && (
        <BorderContainerBig
          flexDirection={{ md: 'row' }}
          height={{ md: '128px' }}
          gap={{ base: theme.spacing[6], lg: theme.spacing[12] }}
        >
          <EnvironmentTitle environmentName={topLevelEnv?.name || ''} subtype="pooled" />
          {TopLevelResistanceBoxes}
          <ScopeSelectors
            title={siteInfo}
            onChangeAntibiotic={
              handleAntibioticChange as unknown as (value: OptionBase | OptionForTargets | null) => void
            }
            onChangeEnvTypeGroup={handleEnvTypeGroupChange}
            selectedTargets={queryFilters.filters.selectedTargets}
          >
            <></>
          </ScopeSelectors>
        </BorderContainerBig>
      )}

      {selectedOrZoomedEnv && (
        <>
          {/* > BREADCRUMB ARROWS > */}
          {previousAdminAreasLifo.length > 0 && (
            <Flex
              sx={{
                height: { base: '25px', lg: 'auto' },
                transform: { base: 'rotate(90deg)', lg: 'none' },
                alignSelf: 'center',
                width: 'min-content',
              }}
              display={{ base: 'none', lg: 'flex' }}
            >
              {previousAdminAreasLifo.map(area => (
                <ArrowIcon
                  direction="right"
                  style={{ color: theme.colors.neutral400, width: '14px' }}
                  key={area.name}
                />
              ))}
            </Flex>
          )}
          {/* SELECTED SITE OR ZOOMED ENVIRONMENT BOX */}
          <BorderContainerBig
            flexDirection={{ md: 'row' }}
            height={{ md: '128px' }}
            gap={{ base: theme.spacing[6], lg: theme.spacing[12] }}
          >
            <EnvironmentTitle
              environmentName={selectedOrZoomedEnv.name || ''}
              subtype={selectedOrZoomedEnv.subtype || 'pooled'}
            />
            <ResistanceBoxes
              beforeOrChange={{
                metric: metricMode === MetricMode.REDUCTION ? primaryMetric : metricBefore,
                level: metricMode === MetricMode.REDUCTION ? primaryLevel : levelBefore,
                label: firstLevelLabel,
              }}
              after={
                metricMode !== MetricMode.REDUCTION && hasAfterAbundances
                  ? {
                      metric: metricAfter,
                      level: levelAfter,
                      label: secondLevelLabel,
                    }
                  : undefined
              }
            />
            <ScopeSelectors
              title={friendlyEnvSubTypeOrType(selectedOrZoomedEnv.subtype, selectedOrZoomedEnv.type)}
              onChangeAntibiotic={
                handleAntibioticChange as unknown as (value: OptionBase | OptionForTargets | null) => void
              }
              onChangeEnvTypeGroup={handleEnvTypeGroupChange}
              selectedTargets={queryFilters.filters.selectedTargets}
            >
              <TutorialContainer
                currentTutorialStep={currentTutorialStep}
                thisTutorialStep={2}
                tutorial={tutorial}
                setTutorialChanged={setTutorialChanged}
                nextStep={nextStep}
                prevStep={prevStep}
                stopTutorial={stopTutorial}
                isMobile={!!isMobile}
                style={{ marginLeft: -200, marginTop: -200 }}
                position="top"
              >
                <></>
                <div style={{ paddingLeft: 15 }}>
                  You can use these filters to
                  <ul style={{ paddingLeft: 30 }}>
                    <li>Focus on a single antibiotic</li>
                    <li>Change the sample type</li>
                    <li>Browse older sampling periods</li>
                  </ul>
                </div>
              </TutorialContainer>
            </ScopeSelectors>
            {selectedSiteDatum && (
              <CloseContainer>
                <CloseIconSimple
                  onClick={() => {
                    toggleEnvironment(undefined, true);
                    previousAdminArea && changeZoomedAdminAreaStable(previousAdminArea, { previous: true });
                  }}
                  style={{
                    color: theme.colors.neutral600,
                  }}
                />
              </CloseContainer>
            )}
          </BorderContainerBig>
        </>
      )}
    </Container>
  );
}

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
  gap: ${theme.spacing[4]};
`;

const BorderContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: ${theme.spacing[6]} ${theme.spacing[8]};
  ${theme.addBorder(300, 'small')}
  background-color: white;
`;
const BorderContainerSmall = styled(BorderContainer)`
  justify-content: center;
  width: auto;
  gap: 13px;
`;
const BorderContainerBig = styled(BorderContainer)`
  padding-right: 0;
  padding-right: 65px;
`;

const CloseContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 38px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSize.h2};
`;
