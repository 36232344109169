import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { groupingName, L2Targets } from '@resistapp/common/assays';

export function constructFocusInfo(
  filters: Filters,
  defaultEnvironmentNames: string[],
  allGeneGroups: Record<string, Array<L2Targets | string>>,
) {
  const groupsString = concatNames(filters.selectedTargets);
  const envsString = concatNames(filters.selectedEnvironmentNamesOrdered);
  const targetInfo: string =
    filters.selectedTargetGrouping === 'l2Target' &&
    filters.selectedTargets.length === allGeneGroups[filters.selectedTargetGrouping].length
      ? ''
      : filters.selectedTargets.length === allGeneGroups[filters.selectedTargetGrouping].length
        ? groupingName[filters.selectedTargetGrouping]
        : groupsString.length < 30
          ? groupsString
          : 'selected genes';
  const envInfo =
    filters.selectedEnvironmentNamesOrdered.length === defaultEnvironmentNames.length
      ? ''
      : envsString.length < 30
        ? envsString
        : 'selected samples';
  return targetInfo && envInfo ? `${envInfo} — ${targetInfo}` : targetInfo || envInfo;
}

// Samples were relabled to enforce sorting in legacy heatmap generation
// const relabeledSampleNumStart = 1000;

function concatNames(names: string[]) {
  if (names.length < 2) {
    return names.join(' and ');
  } else {
    return `${names.slice(0, names.length - 1).join(', ')} and ${names[names.length - 1]}`;
  }
}
