import { useMapContext } from '@resistapp/client/contexts/map-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { isEqual } from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { MapboxMap, Marker as ReactMarker, useMap } from 'react-map-gl';
import { MapRef } from 'react-map-gl/dist/esm/mapbox/create-ref';
import { theme } from '../../shared/theme';
import { getPositionedMarkers, MarkerOverviewDatum } from './marker-utils';
import { onMarkerClick } from './on-marker-click';
import { getMarkerSize, ProcessMarker } from './process-marker';

export const MapMarkers = () => {
  const {
    mapData,
    selectedOrHoveredAreaOrSiteEnvId,
    setHoveredAreaOrSiteEnvIdStable,
    selectedSiteDatum: pinnedMapData,
    previousAdminAreasLifo,
  } = useOverviewContext();
  const { mapInstance, changeZoomedAdminAreaStable, activeMapSource } = useMapContext();
  const { data, queryFilters } = useSampleDataContext();
  const { current: map } = useMap();
  const oldMapDataRef = useRef<OverviewDatum[]>();
  const navigate = useNavigateWithQuery();
  const [positionedMarkers, setPositionedMarkers] = useState<MarkerOverviewDatum[]>([]);

  // We show map markers at the exact location when regions are used
  const adminLevelsActive = Boolean(activeMapSource);
  const onMouseEnter = (marker: MarkerOverviewDatum) => {
    // React does not want state changes when in "render-phase", so we circumvent it with setTimeout
    !adminLevelsActive &&
      !pinnedMapData &&
      data &&
      setTimeout(() => {
        setHoveredAreaOrSiteEnvIdStable(marker.environment.id);
      });

    return '';
  };

  useEffect(() => {
    if (map && mapData?.length && !isEqual(oldMapDataRef.current, mapData)) {
      oldMapDataRef.current = mapData;

      // If zoom changed aggregation level, the labels have to be redrawn here, because the previous redraw happened with old data
      updatePositionedMarkers(mapData, map, adminLevelsActive, setPositionedMarkers);

      map.once('load', () => {
        updatePositionedMarkers(mapData, map, adminLevelsActive, setPositionedMarkers);
      });

      map.on('zoomend', () => {
        updatePositionedMarkers(mapData, map, adminLevelsActive, setPositionedMarkers);
      });
    }
  }, [mapData, map]);

  if (!mapData || !map) {
    return null;
  }

  return (
    <Fragment>
      {positionedMarkers.map(
        (marker, index) =>
          // Show all markers if regions are not shown, if there are regions, show the marker only for that
          // specific region, which is being hovered
          (!adminLevelsActive || isSelected(marker, selectedOrHoveredAreaOrSiteEnvId)) && (
            <ReactMarker
              key={index}
              latitude={getCorrectedLatitudeForAdminArea(marker, map, adminLevelsActive)}
              longitude={getCorrectedLongitudeForAdminArea(marker, map, adminLevelsActive)}
              anchor="bottom"
              style={{
                zIndex:
                  isSelected(marker, pinnedMapData?.environment.id) ||
                  isSelected(marker, selectedOrHoveredAreaOrSiteEnvId)
                    ? theme.zIndexes.markers.selected
                    : theme.zIndexes.markers.default,
              }}
            >
              {/* onMouseEnter is always active when there is a region */}
              {/* {selectedEnvironmentId === marker.environment.id && onMouseEnter(marker)} */}
              <ProcessMarker
                position={marker.position}
                overviewDatum={marker}
                // selected={isSelected(marker, selectedEnvironmentId)}
                pinned={isSelected(marker, pinnedMapData?.environment.id)}
                onClick={close => {
                  onMarkerClick(
                    !!close,
                    marker,
                    navigate,
                    queryFilters.toggleEnvironmentStable,
                    adminLevelsActive,
                    changeZoomedAdminAreaStable,
                    previousAdminAreasLifo[previousAdminAreasLifo.length - 1],
                    mapInstance,
                  );
                }}
                onMouseEnter={() => onMouseEnter(marker)}
              />
            </ReactMarker>
          ),
      )}
    </Fragment>
  );
};

function updatePositionedMarkers(
  mapData: OverviewDatum[],
  map: MapRef<MapboxMap>,
  regionsActive: boolean,
  setPositionedMarkers: React.Dispatch<React.SetStateAction<MarkerOverviewDatum[]>>,
) {
  const freshLabels = getPositionedMarkers(mapData, map, getMarkerSize, regionsActive ? 'center' : undefined);
  setPositionedMarkers(freshLabels);
}

function isSelected(marker: OverviewDatum, selectedOrHoveredAreaOrSiteEnvId?: number) {
  return selectedOrHoveredAreaOrSiteEnvId === marker.environment.id;
}

const MARKER_Y_OFFSET_FROM_REGION_CENTER = 15;
// We calculate the center for lat as we want to center the marker vertically
function getCorrectedLatitudeForAdminArea(
  marker: MarkerOverviewDatum,
  map: MapRef<MapboxMap>,
  regionsActive?: boolean,
) {
  if (!marker.adminLevel?.boundaries?.length || !regionsActive) {
    return marker.inferredLat;
  }

  const lat0 = marker.adminLevel.boundaries[0].lat;
  const lat1 = marker.adminLevel.boundaries[1].lat;
  // Lon doesn't really matter, we are calculating lat
  const lon1 = marker.adminLevel.boundaries[1].lon;
  const centerLat = lat0 + (lat1 - lat0) / 2;
  const asPixels = map.project([lon1, centerLat]);
  const correctGeoCoord = map.unproject([asPixels.x, asPixels.y - MARKER_Y_OFFSET_FROM_REGION_CENTER]);

  return correctGeoCoord.lat;
}

const MARKER_X_OFFSET_FROM_REGION_EDGE = 15;
// We calculate the rightmost position for the Lon to position it outside of the region
function getCorrectedLongitudeForAdminArea(
  marker: MarkerOverviewDatum,
  map: MapRef<MapboxMap>,
  regionsActive?: boolean,
) {
  if (!marker.adminLevel?.boundaries?.length || !regionsActive) {
    return marker.inferredLon;
  }
  // Lat doesn't really matter, we are calculating lon
  const lat1 = marker.adminLevel.boundaries[1].lat;
  const lon1 = marker.adminLevel.boundaries[1].lon;
  const asPixels = map.project([lon1, lat1]);
  const correctGeoCoord = map.unproject([asPixels.x + marker.width / 2 + MARKER_X_OFFSET_FROM_REGION_EDGE, asPixels.y]);

  return correctGeoCoord.lng;
}
