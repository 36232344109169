import styled from '@emotion/styled';
import { LegendInfoIcon } from '@resistapp/client/components/info-icons/legend-info-icon';
import { MetricTitleWithDisclaimer } from '@resistapp/client/components/shared/overview-metric-title';
import { oldCss, theme } from '@resistapp/client/components/shared/theme';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { MetricMode } from '@resistapp/common/types';
import { oldTheme } from '../../../shared/old-styles';

interface Props {
  children: React.ReactNode;
  title?: string;
  description?: React.ReactNode;
  tooltipContent?: React.ReactNode;
}

export function ChartContainer({ children }: Props) {
  const {
    metricMode,
    shownAdminLevel,
    selectedAntibiotic,
    selectedSiteDatum,
    zoomedMapData,
    effectiveSiteDetailsProcessMode,
    activeOverviewConfiguration,
  } = useOverviewContext();
  const title =
    metricMode === MetricMode.ARGI
      ? `${selectedAntibiotic || 'Antibiotic'} Resistance Trend`
      : metricMode === MetricMode.RISK
        ? 'Comparative health risk trend'
        : 'History performance';

  const object = shownAdminLevel === null ? 'site' : 'area';
  const shangesAcrossTimeForObjects = `across time${get_inRawOrEffluentSamplesString(selectedSiteDatum || zoomedMapData, effectiveSiteDetailsProcessMode)} for the selected ${object}(s)`;
  const description =
    metricMode === MetricMode.ARGI ? (
      <>
        <MetricTitleWithDisclaimer noBold /> &nbsp;{shangesAcrossTimeForObjects}
      </>
    ) : metricMode === MetricMode.RISK ? (
      <span>
        <MetricTitleWithDisclaimer noBold /> {shangesAcrossTimeForObjects}
      </span>
    ) : (
      <span>
        <MetricTitleWithDisclaimer noBold /> {shangesAcrossTimeForObjects}
      </span>
    );

  return (
    <Container className="test-chart-container">
      {
        <div style={{ display: 'flex' }}>
          <div>
            {title && <Title>{title}</Title>}
            <InLineDiv>
              {description}
              &nbsp;
              <LegendInfoIcon metricMode={metricMode} activeOverviewConfiguration={activeOverviewConfiguration} />
            </InLineDiv>
          </div>
        </div>
      }
      {children}
    </Container>
  );
}

const InLineDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${oldCss.greyBorder};
  padding: ${theme.spacing[6]};
  background-color: white;
`;

const Title = styled.div`
  font-size: ${oldTheme.fontSize.l};
  font-weight: ${oldTheme.fontWeight.bold};
`;
