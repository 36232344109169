import { antibioticL2Targets, GetGroup, l1TargetByL2, L1Targets, L2Target } from '@resistapp/common/assays';
import { getAbundanceLog10Stats } from '@resistapp/common/statistics/abundance-stats';
import { FullAbundance, NormalisationMode, StrippedFullAbundance } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { LOD } from '../utils';

export type PartialAbundance = Pick<
  FullAbundance,
  'assay' | 'gene' | 'absolute' | 'relative' | 'copiesPerL' | 'copiesPerHour' | 'copiesPerMgSS' | 'copiesPerMgBod'
>;
export type WithAbundances = {
  beforeAbundances?: PartialAbundance[] | undefined;
  afterAbundances?: PartialAbundance[] | undefined;
};

export function getResistanceIndexData(
  abundances: StrippedFullAbundance[],
  l2Targets: L2Target[] | undefined, // undefined means all antibiotics
  getGroup: GetGroup,
) {
  const l1Targets = l2Targets?.map(l2 => getGroup(l2, 'l1Target'));
  if (l1Targets && !l1Targets.every(l1 => l1 === L1Targets.ARG)) {
    throw Error(`Only antibiotics supported in ARGI calculation, got ${l1Targets.join(', ')}`);
  }
  const targets = l2Targets || antibioticL2Targets;
  const data = getAbundanceLog10Stats(
    abundances,
    {
      scope: 'analysed',
      mode: NormalisationMode.SIXTEEN_S,
      targets,
    },
    getGroup,
  );

  const resistanceIndex = abundances.length ? calcResistanceGeneIndex(data.mean ?? Math.log10(LOD)) : null;
  return { resistanceIndex, data };
}

export function getResistanceGeneIndex(
  abundances: StrippedFullAbundance[],
  antibiotic: L2Target[] | undefined, // undefined means all antibiotics
  getGroup: GetGroup,
) {
  const isAntibiotic = (l2Target: L2Target) => l1TargetByL2[l2Target] === L1Targets.ARG;
  if (antibiotic && !antibiotic.every(isAntibiotic)) {
    // This can happen upon early page load in Safari before the filters are properly initialized
    return null;
  }
  return getResistanceIndexData(abundances, antibiotic, getGroup).resistanceIndex;
}

export function calcResistanceGeneIndex(relativeAbundanceExponent: number) {
  return Math.min(5, Math.max(0, 5 + relativeAbundanceExponent));
}

export enum ResistanceLevel {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export function getResistanceLevel(value: number): ResistanceLevel {
  return value >= 4 ? ResistanceLevel.high : value >= 2 ? ResistanceLevel.moderate : ResistanceLevel.low;
}

export function getResistanceGeneIndexAndLevel(
  abundances: StrippedFullAbundance[] | undefined,
  antibiotic: L2Target[] | undefined, // undefined antibiotic means all antibiotics
  getGroup: GetGroup,
) {
  // TODO PERF
  // This  is currently called separately from overview focus header, base chart (2x), Process markers and colored region activation,
  // Activations take 30ms a pop and add up to a similar delay as overview data building (and these are likely called more often)
  //
  if (!abundances) {
    return { resistanceIndex: null, resistanceLevel: null };
  }
  const resistanceIndex = getResistanceGeneIndex(abundances, antibiotic, getGroup);
  const resistanceLevel = isNil(resistanceIndex) ? null : getResistanceLevel(resistanceIndex);
  return { resistanceIndex, resistanceLevel };
}
