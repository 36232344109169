import { Box } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { L2Target } from '@resistapp/common/assays';
import { MetricMode } from '@resistapp/common/types';
import { useState } from 'react';
import { IntegratedTextSelect } from '../selects/integrated-text-select';
import { MetricTitle } from '../shared/overview-metric-title';
import { getGroupColor, getGroupTextColor } from '../shared/palettes';
import { theme } from '../shared/theme';
import { allAntibiotics, OptionForTargets, TargetOrAll } from './types';

interface AntibioticSelectorProps {
  onChange: (value: OptionForTargets | null) => void;
  selectedAntibiotic: OptionForTargets;
  analyzedAntibioticGroups: TargetOrAll[];
  metricMode: MetricMode;
}

export function AntibioticSelector({
  onChange,
  selectedAntibiotic,
  analyzedAntibioticGroups,
}: AntibioticSelectorProps) {
  const [selectActive, setSelectActive] = useState(false);
  const { allGeneGroups } = useAssayContext();

  return (
    <Box
      onClick={() => {
        !selectActive && setSelectActive(true);
      }}
      style={{ cursor: 'pointer' }}
    >
      <IntegratedTextSelect<OptionForTargets>
        options={analyzedAntibioticGroups.map(target => ({
          value: target,
          label: target,
          textColor:
            target === allAntibiotics
              ? theme.colors.neutral800
              : getGroupTextColor(target, 'antibiotic', allGeneGroups),
          bgColor: target === allAntibiotics ? 'white' : getGroupColor(target, 'antibiotic', allGeneGroups),
        }))}
        value={selectedAntibiotic}
        size="sm"
        menuIsOpen={selectActive}
        onChange={selectedOption => {
          onChange(selectedOption);
          setSelectActive(false);
        }}
        onMenuClose={() => {
          setSelectActive(false);
        }}
        renderOption={option => (
          <MetricTitle
            antibioticOverride={option.value === allAntibiotics ? null : (option.value as L2Target)}
            style={{
              color: option.textColor,
              backgroundColor: option.bgColor,
            }}
          />
        )}
      >
        <span style={{ wordWrap: 'normal', whiteSpace: 'nowrap' }}>
          <MetricTitle
            antibioticOverride={
              selectedAntibiotic.value === allAntibiotics ? null : (selectedAntibiotic.value as L2Target)
            }
            style={{ textDecoration: 'underline dotted' }}
          />
        </span>
      </IntegratedTextSelect>
    </Box>
  );
}
