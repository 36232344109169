import {
  antibioticL2Targets,
  AssayInfo,
  GeneGroups,
  GetGroup,
  l1TargetByL2,
  L1Targets,
  L2Targets,
  nonSixteenSL2Targets,
  sixteenS,
} from '@resistapp/common/assays';

import _, { Dictionary, get, keyBy, keys, values } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGet } from '../hooks/api';

interface AssayContextData {
  // All data and getters are initially unstable, but can be counted to be stable and not to cause re-renders after assays have loaded.
  allAssays: AssayInfo[];
  allGeneGroups: GeneGroups;
  getGroup: GetGroup;
  assaysLoaded: boolean;
}

const AssayContext = createContext<AssayContextData | undefined>(undefined);

export function AssayProvider({ children }: { children: ReactNode }) {
  const { data: assayInfoByAssay, isLoading } = useGet<Dictionary<AssayInfo>>('/assays');
  const [allAssays, setAllAssays] = useState<AssayInfo[]>([]);
  const [assayInfoByGene, setAssayInfoByGene] = useState<Dictionary<AssayInfo>>({});
  const [carbapenemGroups, setCarbapenemGroups] = useState<string[]>([]);
  const [mgeGroups, setMgeGroups] = useState<string[]>([]);

  useEffect(() => {
    if (assayInfoByAssay) {
      const assays = values(assayInfoByAssay);
      setAllAssays(assays);
      setAssayInfoByGene(keyBy(assays, row => row.gene));
      setCarbapenemGroups(
        _.chain(assays)
          .map(row => row.carbapenem)
          .compact()
          .uniq()
          .sort()
          .value(),
      );
      setMgeGroups(
        _.chain(assays)
          .map(row => row.mge)
          .compact()
          .uniq()
          .sort()
          .value(),
      );
    }
  }, [assayInfoByAssay]);

  const allGeneGroups = useMemo(() => {
    return {
      ...staticGeneGroups,
      carbapenem: carbapenemGroups,
      mge: mgeGroups,
    };
  }, [carbapenemGroups, mgeGroups]);

  // This is not fully stable, as it's initially created without data, and causes a re-render once assays have loaded.
  // Thus, it is needed in dependency arrays, but can be relied on to be stable after assays have loaded.
  const getGroup = useCallback(
    (
      geneOrAssayOrTarget: string,
      grouping: 'l2Target' | 'l1Target' | 'carbapenem' | 'mge' | 'SIXTEENS_RRNA' | 'antibiotic' = 'l2Target',
    ) => {
      if (geneOrAssayOrTarget in L2Targets) {
        if (grouping === 'l2Target') {
          return geneOrAssayOrTarget;
        } else if (grouping === 'l1Target') {
          return l1TargetByL2[geneOrAssayOrTarget as L2Targets];
        } else if (grouping === 'antibiotic') {
          return l1TargetByL2[geneOrAssayOrTarget as L2Targets] === L1Targets.ARG ? geneOrAssayOrTarget : undefined;
        }
        return undefined;
      }
      if (isLoading) {
        // Odds are that some ignorant codepaths is calling before load has finished, and will be retriggered after.
        // Don't throw as that could result in timing dependent crashes, but do investigate and fix every occurance of this error!
        console.error(new Error(`getGroup called before assays have loaded (${geneOrAssayOrTarget} ${grouping})`));
        return undefined;
      }
      if (!keys(assayInfoByAssay).length) {
        throw Error(`Empty assays`);
      }
      const assayInfo =
        get(assayInfoByAssay, geneOrAssayOrTarget, undefined) || get(assayInfoByGene, geneOrAssayOrTarget, undefined);
      if (!assayInfo) {
        throw Error(`Unrecognized gene or assay in getGroup  ${geneOrAssayOrTarget}`);
      }
      const group = assayInfo[grouping];
      if (grouping === 'l2Target' && !group) {
        throw Error('Assay info is missing target');
      }
      return group;
    },
    [assayInfoByAssay, assayInfoByGene, isLoading],
  );

  const assaysLoaded = !isLoading && allAssays.length > 0 && !!assayInfoByAssay && keys(assayInfoByAssay).length > 0;
  const contextValue: AssayContextData = {
    allGeneGroups,
    allAssays,
    getGroup,
    assaysLoaded,
  };

  return <AssayContext.Provider value={contextValue}>{children}</AssayContext.Provider>;
}

export function useAssayContext() {
  const context = useContext(AssayContext);
  if (!context) {
    throw new Error('useAssayContext must be used within an AssayProvider');
  }
  return context;
}

const staticGeneGroups: Record<string, Array<L2Targets | string>> = {
  [sixteenS]: [sixteenS],
  l2Target: nonSixteenSL2Targets,
  l1Target: Object.values(L1Targets),
  antibiotic: antibioticL2Targets,
};
