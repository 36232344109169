import { Dictionary, get, keyBy, mapValues, mean, uniq } from 'lodash';
import { GeneGrouping, GetGroup, sixteenS } from './assays';
import { getSampleUID } from './sample-uid-utils';
import { Abundance, AbundanceByBA, AbundancesByUBA, FullAbundance, FullSample } from './types';
import { getBioNumber } from './utils';

export function buildSortIdxByGene(
  allSamples: FullSample[],
  grouping: GeneGrouping,
  getGroup: GetGroup,
  focusedSamples?: FullSample[],
) {
  const abundanceBySBA = getAbundancesBySBA(allSamples);
  const allAssays = getUniqueAssays(allSamples).filter(assay => getGroup(assay) !== sixteenS || grouping === sixteenS);
  const meanByGroup = calcMeanByGroup(abundanceBySBA, grouping, false, getGroup);
  const meanByGene = calcMeanByAssay(abundanceBySBA, false);
  const tracesByGroup = calcMeanByGroup(abundanceBySBA, grouping, true, getGroup);
  const tracesByGene = calcMeanByAssay(abundanceBySBA, true);

  // TODO support duplicate genes ?
  const assayToGene: Dictionary<string> = {};
  allSamples.forEach(sample => {
    sample.abundances.forEach(abundance => {
      assayToGene[abundance.assay] = abundance.gene;
    });
  });

  allAssays.sort((assayA, assayB) => {
    // The gene fallbacks are for 16s
    const groupA = getGroup(assayA, grouping) as string;
    const groupB = getGroup(assayB, grouping) as string;

    const groupPart = meanByGroup[groupB] - meanByGroup[groupA];
    const groupTrace = tracesByGroup[groupB] - tracesByGroup[groupA];
    const groupAlphaPart = groupA > groupB ? -1 : groupB > groupA ? 1 : 0;
    const genePart = meanByGene[assayB] - meanByGene[assayA];
    const geneTrace = tracesByGene[assayB] - tracesByGene[assayA];
    const assayAlphaPart = assayA < assayB ? -0.01 : assayB < assayA ? 0.01 : 0;
    return (groupPart || groupTrace || groupAlphaPart) * 1000000000 + (genePart || geneTrace || assayAlphaPart);
  });

  let idx = 0;
  const focusedAssays = focusedSamples && getUniqueAssays(focusedSamples);
  const assayIsFocused = focusedAssays && keyBy(focusedAssays, a => a);
  return allAssays.reduce<Dictionary<number>>((acc, assay) => {
    if (!assayIsFocused || assayIsFocused[assay]) {
      const gene = assayToGene[assay];
      if (gene) {
        acc[gene] = idx++;
      }
    }
    return acc;
  }, {});
}

function getUniqueAssays(samples: FullSample[]) {
  const allAssays = samples.reduce<string[]>((acc, sample) => {
    sample.abundances.forEach(r => {
      acc.push(r.assay);
    });
    return acc;
  }, []);
  const assays = uniq(allAssays);
  if (allAssays.length !== assays.length * samples.length) {
    throw Error('All assays not analysed for all samples');
  }
  return assays;
}

export function getAbundancesBySBA(samples: FullSample[]): AbundancesByUBA {
  return samples.reduce<AbundancesByUBA>((acc, sample) => {
    const UID = getSampleUID(sample);
    const byBA = acc[UID] || {};
    if (byBA[sample.bioRep]) {
      throw Error(`Duplicate bio sample ${getBioNumber(sample)}`);
    }
    byBA[sample.bioRep] = sample.abundances.reduce<Dictionary<FullAbundance>>((acc2, r) => {
      if (get(acc2, r.assay, undefined)) {
        throw Error(`Duplicate abundance for sample ${getBioNumber(sample)} ${r.assay}`);
      }
      acc2[r.assay] = r;
      return acc2;
    }, {});
    acc[UID] = byBA;
    return acc;
  }, {});
}

function calcMeanByGroup(abundanceByUBG: AbundancesByUBA, grouping: GeneGrouping, traces: boolean, getGroup: GetGroup) {
  const valuesByGroup: Dictionary<number[]> = buildValuesBy(abundanceByUBG, assay => getGroup(assay, grouping), traces);
  return mapValues(valuesByGroup, mean);
}

export function calcMeanByAssay(abundanceBySBG: AbundancesByUBA, traces: boolean) {
  const valuesByAssay: Dictionary<number[]> = buildValuesBy(abundanceBySBG, assay => assay, traces);
  return mapValues(valuesByAssay, mean);
}

function buildValuesBy(abundanceByUBA: AbundancesByUBA, by: (assay: string) => string | undefined, traces = false) {
  const valuesBy: Dictionary<number[]> = {};
  Object.values(abundanceByUBA).forEach((abundanceByBA: AbundanceByBA | undefined) => {
    if (!abundanceByBA) {
      throw Error('Unexpected undefined abundanceByBG');
    }
    Object.values(abundanceByBA).forEach((abundanceByG?: Dictionary<Abundance>) => {
      if (!abundanceByG) {
        throw Error('Unexpected undefined abundanceByG');
      }
      Object.values(abundanceByG).forEach(r => {
        const b = by(r.assay) as string;
        if (!get(valuesBy, b, undefined)) {
          valuesBy[b] = [];
        }
        valuesBy[b].push(traces ? +r.traces : r.relative || 0);
      });
    });
  });
  return valuesBy;
}
