import { ascending, quantileSorted, sum } from 'd3-array';
import { chain, isNil } from 'lodash';
import { AbundanceStats } from '../api-types';
import { antibioticL2Targets, GetGroup, L1Targets, L2Target } from '../assays';
import { getNormalisedValue, isConsideredAbsolute, type NormalisationModesAndProperties } from '../normalisation-mode';
import { StrippedFullAbundance } from '../types';
import { replaceZerosWithLod } from '../utils';

interface Log10StatOptions {
  mode: keyof typeof NormalisationModesAndProperties;
  scope: 'detected' | 'analysed';
  targets: L2Target[];
}

export function getAbundanceStatsMin(stats: AbundanceStats[]) {
  return Math.min(...flattenValues(stats));
}

export function getAbundanceStatsMax(stats: AbundanceStats[]) {
  return Math.max(...flattenValues(stats));
}

function flattenValues(stats: AbundanceStats[]) {
  return chain(stats)
    .filter(datum => datum.max !== undefined && datum.min !== undefined && datum.outliers !== undefined)
    .map(datum => [datum.min, datum.max, ...(datum.outliers || [])])
    .flatten()
    .compact()
    .value();
}

export function calculateLog10Stats(values: Array<number | undefined | null>): AbundanceStats {
  const log10Values = values.map(v => v && Math.log10(v));
  return calcStats(log10Values);
}

export function calcStats(values: Array<number | undefined | null>): AbundanceStats {
  const numbers = values.filter(v => !!v || v === 0.0) as number[];
  if (!numbers.length) {
    return {
      min: undefined,
      max: undefined,
      median: undefined,
      mean: undefined,
      firstQuartile: undefined,
      thirdQuartile: undefined,
      outliers: undefined,
    };
  }
  const sorted = numbers.sort(ascending);
  const firstQuartile = quantileSorted(sorted, 0.25);
  const median = quantileSorted(sorted, 0.5);
  const mean = sum(sorted) / sorted.length;
  const thirdQuartile = quantileSorted(sorted, 0.75);
  if (median === undefined || firstQuartile === undefined || thirdQuartile === undefined) {
    throw Error('Unable to calculate box plot quartiles');
  }

  const interquartileRange = thirdQuartile - firstQuartile;
  const minTresh = firstQuartile - 1.5 * interquartileRange;
  const maxTresh = thirdQuartile + 1.5 * interquartileRange;

  const isInlier = (n: number) => n >= minTresh && n <= maxTresh;
  const outliers = sorted.filter(n => !isInlier(n));
  const inliers = sorted.filter(isInlier);
  const reverseInliers = inliers.concat().reverse();

  const min = inliers[0];
  const max = reverseInliers[0];
  if (isNil(min) || isNil(max)) {
    throw Error('Unable to pick box plot statistics');
  }

  return {
    min,
    firstQuartile,
    median,
    mean,
    thirdQuartile,
    max,
    outliers,
  };
}

export function getAbundanceLog10Stats(
  relevantAbundances: StrippedFullAbundance[],
  options: Log10StatOptions,
  getGroup: GetGroup,
) {
  const { mode, scope, targets } = options;
  const targetedAbundances = filterTargetAbundances(relevantAbundances, targets, getGroup);

  // Note: neither absolute nor relative can be zero, so this should not be mixing nulls with zeroes
  const detectedAndZeroes = targetedAbundances.map(abundance => getNormalisedValue(abundance, mode) ?? 0);
  const inScopeValues =
    scope === 'detected'
      ? detectedAndZeroes.filter(v => !!v)
      : isConsideredAbsolute(mode)
        ? detectedAndZeroes
        : replaceZerosWithLod(detectedAndZeroes);

  const data = calculateLog10Stats(inScopeValues);
  return data;
}

export function filterTargetAbundances(abundances: StrippedFullAbundance[], targets: L2Target[], getGroup: GetGroup) {
  const targetSet = new Set(targets);
  const isAntibioticTargets =
    targetSet.size === antibioticL2Targets.length && antibioticL2Targets.every(target => targetSet.has(target));
  const targetedAbundances = isAntibioticTargets // TODO remove temporary hack, see isAntibiotic
    ? abundances.filter(abundance => getGroup(abundance.assay, 'l1Target') === L1Targets.ARG)
    : abundances.filter(abundance => targetSet.has(getGroup(abundance.assay, 'l2Target') as L2Target));
  return targetedAbundances;
}
