import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useTrendChartContext } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import {
  getBeforeOrAfterAbundances,
  type OverviewDatum,
} from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getResistanceGeneIndexAndLevel } from '@resistapp/common/statistics/resistance-index';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { positioning } from '../chart-styles';
import { ResistanceIndexLegend } from '../resistance-index-legend';
import { getResistanceIndexScale } from '../scales';
import { BaseChart } from './base-chart';

interface ArgiChartProps {
  queryFilters: {
    filters: Pick<Filters, 'selectedTargets'>;
  };
}

export function ArgiChart(props: ArgiChartProps) {
  const { queryFilters, ...restProps } = props;
  const { getGroup } = useAssayContext();
  const { metricMode, effectiveSiteDetailsProcessMode } = useOverviewContext();
  const {
    trenchartTooltip: { trendChartSize, TooltipComponentForARGIMarker },
  } = useTrendChartContext();

  const graphHeight = trendChartSize.height - positioning.margin.top - positioning.margin.bottom;

  const resistanceIndexScale = useMemo(() => getResistanceIndexScale(graphHeight), [graphHeight]);
  const getValue = useCallback(
    (datum: OverviewDatum) => {
      const abundances = getBeforeOrAfterAbundances(datum, metricMode, effectiveSiteDetailsProcessMode);
      const { resistanceIndex } = getResistanceGeneIndexAndLevel(
        abundances,
        queryFilters.filters.selectedTargets,
        getGroup,
      );
      return isNil(resistanceIndex) ? undefined : resistanceIndexScale(resistanceIndex);
    },
    [metricMode, effectiveSiteDetailsProcessMode, queryFilters.filters.selectedTargets, getGroup, resistanceIndexScale],
  );

  return (
    <BaseChart
      {...restProps}
      width={trendChartSize.width}
      height={trendChartSize.height}
      indexScale={resistanceIndexScale}
      labelScale={resistanceIndexScale}
      Legend={ResistanceIndexLegend}
      numTicks={6}
      getValue={getValue}
      showData={{ quartileRange: true, referenceLines: true }}
      TooltipComponent={TooltipComponentForARGIMarker}
    />
  );
}
