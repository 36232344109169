import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSearchParamsContext } from '@resistapp/client/contexts/search-params-context';
import { GeneGrouping, L2Target, L2Targets, sixteenS } from '@resistapp/common/assays';
import {
  AllProjectEnvironmentTypesGroup,
  EnvironmentTypeGroup,
  allEnvGroupTypes,
  filterGroupEnvironments,
  getComparableEnvironmentGroups,
} from '@resistapp/common/comparable-env-groups';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureValidUtcMidnightOrDefault,
} from '@resistapp/common/friendly';
import { isConsideredAbsolute } from '@resistapp/common/normalisation-mode';
import { Environment, FullProject, FullSamplesByUID, NormalisationMode } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import {
  AbunanceSelection,
  Filters,
  filterSamplesAndAbundances,
  getNextToggledStrings,
} from '../../data-utils/filter-data/filter';
import {
  QueryParams,
  getEnvironmentTypeParam,
  getEnvironmentsParams,
  getGeneGroupingParams,
  getGeneGroupsParams,
  getNormalisationModeParam,
  mutateEnvironmentRelatedSearchParams,
  mutateSearchParamsWithSelection,
} from '../../utils/url-manipulation';
import { constructFocusInfo } from './use-query-filters-utils';

export interface UseQueryFilters {
  focusSamplesByUID: (samplesByUID: FullSamplesByUID) => FullSamplesByUID | undefined;
  queryFilters: QueryFilters;
}

type ToggleGeneGroup = (
  group: L2Target | L2Target[],
  removeOldSelections: boolean,
  selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
) => void;

export interface QueryFilters {
  setGroupingStable: (grouping: GeneGrouping) => void;
  setEnvironmentTypeGroupStable: (type: EnvironmentTypeGroup, replaceAndClearEnvs: boolean) => void;
  toggleEnvironmentStable: (name: string | string[] | undefined, removeOldSelections: boolean) => void;
  setMatchingEnvironmentsAcrossTypes: (search: string) => void;
  hasFocus: boolean;
  toggleGeneGroupStable: ToggleGeneGroup;
  filters: Filters;
  focusInfo: string;
  setAbundanceModeStable: (mode: AbunanceSelection) => void;
  setNormalisationModeStable: (value: NormalisationMode) => void;
  resetFiltersStable: () => void;
  setIntervalStable: (start: Date | string, end: Date | string) => void;
}

const defaultAbundanceMode = AbunanceSelection.ANALYSED;

function useFiltersInternal() {
  const { allGeneGroups } = useAssayContext();
  const [abundanceMode, setAbundanceModeStable] = useLocalStorageState(LocalStorageId.abundanceMode, {
    defaultValue: defaultAbundanceMode,
  });

  const { searchParams } = useSearchParamsContext();
  const start = ensureValidUtcMidnightOrDefault(searchParams.get(QueryParams.START), DEFAULT_START_INTERVAL);
  const end = ensureValidUtcMidnightOrDefault(searchParams.get(QueryParams.END), DEFAULT_END_INTERVAL);

  const [allProjectEnvironments, setAllProjectEnvironmentsStable] = useState<Environment[]>([]);
  const [allProjectEnvironmentNames, setAllProjectEnvironmentNamesStable] = useState<string[]>([]);

  const geneGroupsParam = searchParams.get(QueryParams.GENE_GROUPS);
  const queryGeneGroups = useMemo(() => getGeneGroupsParams(geneGroupsParam), [geneGroupsParam]);
  const queryGeneGrouping = getGeneGroupingParams(searchParams, allGeneGroups);

  const envNamesHaveComma = allProjectEnvironmentNames.join('').includes(','); // For url backwards compatibility: legacy query param array separator support
  const envs = searchParams.get(QueryParams.ENVIRONMENTS);
  const queryEnvironmentNames = useMemo(
    () => getEnvironmentsParams(envs, envNamesHaveComma),
    [envNamesHaveComma, envs],
  );
  const queryEnvironmentGroup =
    getEnvironmentTypeParam(searchParams) ?? AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;

  const queryNormalisationMode = getNormalisationModeParam(searchParams);
  useEffect(() => {
    // Convert legacy value
    if ((abundanceMode as unknown) === 'ANALYZED') {
      setAbundanceModeStable(AbunanceSelection.ANALYSED);
    }
  }, [abundanceMode]);

  const environmentsImplicitlySelectedWithEnvTypeGroupSelection = useMemo(
    () => filterGroupEnvironments(allProjectEnvironments, queryEnvironmentGroup),
    [allProjectEnvironments, queryEnvironmentGroup],
  );

  // Memoize selected environment names array
  // NOTE: this is project dependent and always runs after project is loaded
  // and this behaviour is exected by ResearchProvider for determining when to buildResearchPlotData
  const selectedEnvironmentNamesOrdered = useMemo(
    () =>
      queryEnvironmentNames.length
        ? queryEnvironmentNames
        : environmentsImplicitlySelectedWithEnvTypeGroupSelection.map(e => e.name),
    [queryEnvironmentNames, environmentsImplicitlySelectedWithEnvTypeGroupSelection],
  );

  // Memoize selected targets array
  const selectedTargets = useMemo(
    () => (queryGeneGroups.length ? queryGeneGroups : allGeneGroups[queryGeneGrouping]) as L2Targets[],
    [queryGeneGroups, allGeneGroups, queryGeneGrouping],
  );

  // Memoize interval object
  const interval = useMemo(
    () => ({
      start,
      end,
    }),
    // This warning is ok, but for some reason eslint does not allow surpresing it
    // eslint-disable-next-line exhaustive-deps-with-refs/exhaustive-deps
    [start.getTime(), end.getTime()],
  );

  // A convenience object containing the active query params
  // or all values when multi-select query params are not set
  // NOTE: each member is separately memoized for safe referencing in dependency arrays.
  // NOTE! NEVER use the full filters object in dependency arrays.
  const filters = useMemo<Filters>(
    () => ({
      selectedEnvironmentTypeGroup: queryEnvironmentGroup,
      selectedEnvironmentNamesOrdered,
      selectedTargetGrouping: queryGeneGrouping,
      selectedTargets,
      interval,
      normalisationMode: queryNormalisationMode,
      abundances: abundanceMode,
    }),
    [
      queryEnvironmentGroup,
      selectedEnvironmentNamesOrdered,
      queryGeneGrouping,
      selectedTargets,
      interval,
      queryNormalisationMode,
      abundanceMode,
    ],
  );

  return {
    filters,
    setAllProjectEnvironmentsStable,
    setAllProjectEnvironmentNamesStable,
    allProjectEnvironments,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    queryEnvironmentNames,
    queryGeneGroups,
    queryGeneGrouping,
    setAbundanceModeStable,
    allProjectEnvironmentNames,
  };
}

export function useNormalisationMode(): NormalisationMode {
  const { searchParams } = useSearchParamsContext();
  return useMemo(() => getNormalisationModeParam(searchParams), [searchParams]);
}

export function useFilters(): Filters {
  const { filters } = useFiltersInternal();
  return filters;
}

export function useQueryFilters(project: FullProject | undefined): UseQueryFilters {
  // console.log('useQueryFilters');
  const { searchParams, setSearchParamsStable, searchParamsRef } = useSearchParamsContext();
  const { allGeneGroups, getGroup, assaysLoaded } = useAssayContext();
  const {
    filters,
    setAllProjectEnvironmentsStable,
    setAllProjectEnvironmentNamesStable,
    allProjectEnvironments,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    queryEnvironmentNames,
    queryGeneGroups,
    queryGeneGrouping,
    setAbundanceModeStable,
    allProjectEnvironmentNames,
  } = useFiltersInternal();
  const location = useLocation();
  const [allProjectEnvironmentTypes, setAllProjectEnvironmentTypes] = useState<EnvironmentTypeGroup[]>([]);

  // - reintroduce useStateWithRef and useCallbackRef ?
  // - use create care to determine dep vs ref status for every useEffect variable
  // - confirm the below
  // - switch to stable setSearchParams impl if you must
  // NOTE / TODO CONFIRM !! searchParams may trigger all it's dependent useEffects on every query change?!
  // Stable functions:
  // Setter not stable: https://github.com/remix-run/react-router/issues/9991

  useEffect(() => {
    if (!project) {
      return;
    }

    const projectEnvironments = sortUniqEnvironments(project.samplesByUID);
    const allEnvironmentNames = projectEnvironments.map(env => env.name);

    // Get both regular env types and comparable groups
    const comparableGroups = getComparableEnvironmentGroups(projectEnvironments, undefined);
    const allProjectEnvironmentTypesToBeSet = uniq([
      ...comparableGroups.map(g => g.type),
      ...projectEnvironments.map(env => env.type),
    ]);

    setAllProjectEnvironmentTypes(allProjectEnvironmentTypesToBeSet);
    setAllProjectEnvironmentsStable(projectEnvironments);
    setAllProjectEnvironmentNamesStable(allEnvironmentNames);
  }, [project]);

  useEffect(() => {
    // This should never be empty after data is loaded, and triggering this effect before
    // data is loaded would clear the environment names from query params
    if (!environmentsImplicitlySelectedWithEnvTypeGroupSelection.length) {
      return;
    }
    const allEnvTypeGroupEnvNames = allProjectEnvironments.map(e => e.name);
    const initialEnvironmentNames = queryEnvironmentNames.length
      ? getNextToggledStrings(queryEnvironmentNames, allEnvTypeGroupEnvNames, allEnvTypeGroupEnvNames, true)
      : allProjectEnvironments.map(e => e.name);

    mutateSearchParamsWithSelection(
      QueryParams.ENVIRONMENTS,
      searchParamsRef.current,
      allEnvTypeGroupEnvNames,
      initialEnvironmentNames,
      true,
    );
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, [queryEnvironmentNames, environmentsImplicitlySelectedWithEnvTypeGroupSelection, allProjectEnvironments]);

  // (RE)SET GENE GROUPS ON PROJECT, ABS. MODE OR GROUPING CHANGE
  const queryGeneGroupsRef = useRef(queryGeneGroups);
  queryGeneGroupsRef.current = queryGeneGroups;
  useEffect(() => {
    if (!assaysLoaded) {
      return;
    }
    const groups = queryGeneGroupsRef.current.length
      ? getNextToggledStrings(
          queryGeneGroupsRef.current,
          allGeneGroups[queryGeneGrouping],
          allGeneGroups[queryGeneGrouping],
          true,
        )
      : allGeneGroups[queryGeneGrouping];

    mutateSearchParamsWithSelection(
      QueryParams.GENE_GROUPS,
      searchParamsRef.current,
      allGeneGroups[queryGeneGrouping],
      groups,
      true,
    );
    setSearchParamsStable(searchParamsRef.current, { replace: true });

    // Only triggered on project, abs. mode or grouping change!
  }, [filters.abundances, location.pathname, queryGeneGrouping, assaysLoaded, allGeneGroups]);

  const setGroupingStable = useCallback((value: GeneGrouping) => {
    searchParamsRef.current.set(QueryParams.GENE_GROUP_GROUPING, value);
    searchParamsRef.current.delete(QueryParams.GENE_GROUPS);
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, []);
  const absoluteModeOn = isConsideredAbsolute(filters.normalisationMode);
  useEffect(() => {
    if (!absoluteModeOn && queryGeneGrouping === sixteenS) {
      setGroupingStable('l2Target');
    }
  }, [absoluteModeOn, queryGeneGrouping]);

  const setEnvironmentTypeGroupStable = useCallback((newGroup: EnvironmentTypeGroup, replaceAndClearEnvs: boolean) => {
    const validGroup = allEnvGroupTypes.includes(newGroup)
      ? newGroup
      : AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;
    mutateEnvironmentRelatedSearchParams(searchParamsRef.current, validGroup, replaceAndClearEnvs);
    setSearchParamsStable(searchParamsRef.current, { replace: replaceAndClearEnvs });
  }, []);

  const selectedEnvironmentNamesOrderedRef = useRef(filters.selectedEnvironmentNamesOrdered);
  selectedEnvironmentNamesOrderedRef.current = filters.selectedEnvironmentNamesOrdered;
  const allProjectEnvsRef = useRef(allProjectEnvironments);
  allProjectEnvsRef.current = allProjectEnvironments;
  const toggleEnvironmentStable = useCallback((name: string | string[] | undefined, removeOldSelections: boolean) => {
    const selectedEnvironmentNames = allProjectEnvsRef.current.map(e => e.name);
    const next = name
      ? getNextToggledStrings(
          name,
          selectedEnvironmentNamesOrderedRef.current,
          selectedEnvironmentNames,
          removeOldSelections,
        )
      : selectedEnvironmentNames;

    mutateSearchParamsWithSelection(
      QueryParams.ENVIRONMENTS,
      searchParamsRef.current,
      selectedEnvironmentNames,
      next,
      removeOldSelections,
    );

    setSearchParamsStable(searchParamsRef.current, { replace: true });

    // Scroll to the map, when the selected environment changes
    const mapElement = document.querySelector('.mapboxgl-map');
    if (mapElement) {
      mapElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const queryGeneGroupingRef = useRef(queryGeneGrouping);
  queryGeneGroupingRef.current = queryGeneGrouping;
  const selectedTargetsRef = useRef(filters.selectedTargets);
  selectedTargetsRef.current = filters.selectedTargets;
  const allGeneGroupsRef = useRef(allGeneGroups);
  allGeneGroupsRef.current = allGeneGroups;
  const toggleGeneGroupStable = useCallback(
    (
      group: L2Target | L2Target[],
      removeOldSelections: boolean,
      selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
    ) => {
      const realSelectedGrouping = selectedGroupingInCaseStateHasNotUpdated ?? queryGeneGroupingRef.current;
      const next = getNextToggledStrings<L2Target>(
        group,
        selectedTargetsRef.current,
        allGeneGroupsRef.current[realSelectedGrouping] as L2Target[],
        removeOldSelections,
      );
      mutateSearchParamsWithSelection(
        QueryParams.GENE_GROUPS,
        searchParamsRef.current,
        allGeneGroupsRef.current[realSelectedGrouping],
        next,
        removeOldSelections,
      );
      setSearchParamsStable(searchParamsRef.current, { replace: true });
    },
    [],
  );

  const focusSamplesByUID = useCallback(
    (samplesByUID: FullSamplesByUID) => {
      if (!assaysLoaded) {
        return undefined;
      }
      return filterSamplesAndAbundances(samplesByUID, filters, getGroup);
    },
    [assaysLoaded, getGroup, filters],
  ); // Note: this is intentionally and unstable callback!

  const setNormalisationModeStable = useCallback((value: NormalisationMode) => {
    searchParamsRef.current.set(QueryParams.NORMALISATION_MODE, value);
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, []);

  const resetFiltersStable = useCallback(() => {
    setSearchParamsStable({}, { replace: true });
    setAbundanceModeStable(defaultAbundanceMode);
  }, []);

  const setIntervalStable = useCallback((requestedStart: Date | string, requestedEnd: Date | string) => {
    const snappedStart = ensureValidUtcMidnightOrDefault(requestedStart, DEFAULT_START_INTERVAL);

    if (snappedStart.getTime() === DEFAULT_START_INTERVAL.getTime()) {
      searchParamsRef.current.delete('start');
    } else {
      searchParamsRef.current.set('start', snappedStart.toISOString().slice(0, 10));
    }
    const snappedEnd = ensureValidUtcMidnightOrDefault(requestedEnd, DEFAULT_END_INTERVAL);
    if (snappedEnd.getTime() === DEFAULT_END_INTERVAL.getTime()) {
      searchParamsRef.current.delete('end');
    } else {
      searchParamsRef.current.set('end', snappedEnd.toISOString().slice(0, 10));
    }
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, []);

  return {
    focusSamplesByUID,
    queryFilters: {
      focusInfo: constructFocusInfo(filters, allProjectEnvironmentNames, allGeneGroups),
      toggleGeneGroupStable,
      setGroupingStable,
      filters,
      setAbundanceModeStable,
      setMatchingEnvironmentsAcrossTypes: (search: string) => {
        const newlyMatchedEnvNames = getLowerCaseMatches(search, allProjectEnvironmentNames);
        if (!search || !newlyMatchedEnvNames.length) {
          return;
        }
        const newlyMatchedEnvs = allProjectEnvironments.filter(e => newlyMatchedEnvNames.includes(e.name));
        const newlyMatchedEnvGroups = getComparableEnvironmentGroups(newlyMatchedEnvs, undefined);

        const allNewlyMatchedEnvTypes = uniq(newlyMatchedEnvs.map(e => e.type));
        setEnvironmentTypeGroupStable(newlyMatchedEnvGroups[0].type, false);
        const allEnvNamesOfTheTheSelectedTypes = allProjectEnvironments
          .filter(e => allNewlyMatchedEnvTypes.includes(e.type))
          .map(e => e.name);

        const next = getNextToggledStrings(
          newlyMatchedEnvNames,
          allProjectEnvironmentNames,
          allEnvNamesOfTheTheSelectedTypes,
          true,
        );
        mutateSearchParamsWithSelection(
          QueryParams.ENVIRONMENTS,
          searchParams,
          allEnvNamesOfTheTheSelectedTypes,
          next,
          true,
        );
        setSearchParamsStable(searchParams, { replace: true });
      },
      toggleEnvironmentStable,
      setEnvironmentTypeGroupStable,
      setNormalisationModeStable,
      hasFocus:
        queryGeneGrouping !== 'l2Target' ||
        filters.selectedTargets.length < allGeneGroups[queryGeneGrouping].length ||
        // NOTE: this doesn't check whether all project envs are within a selected custom group, but just conservatively assumes there may be focus
        // TODO: fix if it turns out to be an issue
        (filters.selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS &&
          !(
            allProjectEnvironmentTypes.length === 1 &&
            filters.selectedEnvironmentTypeGroup === allProjectEnvironmentTypes[0]
          )) ||
        (filters.selectedEnvironmentNamesOrdered.length &&
          filters.selectedEnvironmentNamesOrdered.length < allProjectEnvironmentNames.length) ||
        filters.interval.start.getTime() !== DEFAULT_START_INTERVAL.getTime() ||
        filters.interval.end.getTime() !== DEFAULT_END_INTERVAL.getTime(),
      resetFiltersStable,
      setIntervalStable,
    },
  };
}

export function getLowerCaseMatches(searchString: string, stringsToMatchTo: string[]) {
  const lowerSearchString = searchString.toLowerCase();
  return stringsToMatchTo.filter(str => str.toLowerCase().includes(lowerSearchString));
}
